<template>
  <div class="v-container" style="background-color: white; height: 700px">
    <div class="sub-container">
      <!-- <div class="v-title">
        <div class="v-title-left">{{ nowTime }}</div>
        工地巡查大屏
        <div class="v-title-right">
          <span @click="jumpBack"><a-icon type="logout" /> 返回</span>
        </div>
      </div> -->

      <div style="display: flex; margin-top: 20px">
        <!-- <div style="flex: 1; padding-right: 20px">
          <SitePreview :record="record" :pageType="'patrol'"></SitePreview>
        </div> -->
        <!-- <div class="v-title" style="width: 30%;"> -->
        <div class="v-msg">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="工地信息">
              <div v-for="item in siteInfo" :key="item.key" class="info-box">
                <div class="box-label">{{ item.label }}</div>
                <div class="box-value">{{ item.value }}</div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="设备信息">
              <div v-for="item in deviceInfo" :key="item.key" class="info-box">
                <div class="box-label">{{ item.label }}</div>
                <div class="box-value">{{ item.value }}</div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <!-- <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    > -->
    <!-- <SiteSnapshot :record="record" v-if="SiteSnapshot"></SiteSnapshot>
      <SitePlayback :record="record" v-if="SitePlayback"></SitePlayback>
      <SiteAttend :record="record" v-if="SiteAttend"></SiteAttend> -->
    <!-- </a-drawer> -->
  </div>
</template>
<script>
import SitePreview from "@/views/site/SitePreview.vue";
import SiteSnapshot from "@/views/site/SiteSnapshot.vue";
import SitePlayback from "@/views/site/SitePlayback.vue";
import SiteAttend from "@/views/site/SiteAttend.vue";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetSiteInfo } from "@/api/patrol.js";
export default {
  name: "SiteDetail",
  components: { SitePreview, SiteSnapshot, SitePlayback, SiteAttend },
  data() {
    return {
      ws_id: "",
      deviceInfo: [
        { label: "设备状态", key: "d_status", value: "--" },
        { label: "设备IMEI号", key: "d_imei", value: "--" },
        { label: "设备账号", key: "d_htxid", value: "--" },
        { label: "设备分配日期", key: "d_distribution_time", value: "--" },
        { label: "设备启用日期", key: "d_time", value: "--" },
        { label: "设备绑定人", key: "d_w_name", value: "--" },
        { label: "套餐剩余天数", key: "d_remain_day", value: "--" },
      ],
      siteInfo: [
        { label: "分公司id", key: "ws_id", value: "ws_id" },
        { label: "分公司", key: "ws_bc_name", value: "--" },
        { label: "工地名称", key: "ws_name", value: "--" },
        { label: "工程管家", key: "ws_gj", value: "--" },
        { label: "项目经理", key: "ws_jl", value: "--" },
        { label: "绑定时间", key: "ws_bind_time", value: "--" },
        { label: "无人施工天数", key: "ws_nowork", value: "--" },
        { label: "工地状态", key: "ws_status", value: "--" },
        { label: "打卡状态", key: "ws_attendance", value: "--" },
        { label: "打卡人数", key: "ws_a_sum", value: "--" },
      ],
      logInfo: [
        { label: "操作账号", key: "u_phone", value: "--" },
        { label: "岗位", key: "u_post", value: "--" },
        { label: "姓名", key: "u_name", value: "--" },
        { label: "描述", key: "u_msg", value: "--" },
        { label: "操作时间", key: "u_time", value: "--" },
      ],
      // 弹出框
      visible: false,
      title: "",
      record: {
        ws_name: "",
        ws_id: "",
      },
      SiteAttend: false,
      SiteSnapshot: false,
      SitePlayback: false,
      timer: "", //定义一个定时器
      nowTime: "", // 当前日期时间
    };
  },
  created() {
    // this.ws_id = this.$route.query.ws_id;
    this.ws_id = this.windowPosition.ws_id;
    this.fetchData();
  },
  mounted() {
    this.getTime();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];

        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    fetchData() {
      let params = {
        ws_id: this.ws_id,
      };
      GetSiteInfo(params).then((res) => {
        let data = res.data;
        if (data.device) {
          this.deviceInfo.forEach((el) => {
            let key = el.key;
            if (["d_distribution_time", "d_time"].includes(key)) {
              el.value = this.$Format(data.device[key], "date");
            } else if (["d_remain_day"].includes(key)) {
              el.value = this.$calcDate(data.device[key], "date");
            } else if (["d_status"].includes(key)) {
              el.value = this.statusFilter(
                data.device[key],
                SelectOptions.SiteDeviceStatus
              );
            } else {
              el.value = data.device[key];
            }
          });
        }
        if (data.worksite) {
          this.siteInfo.forEach((el) => {
            let key = el.key;
            if (["ws_bind_time"].includes(key)) {
              el.value = this.$Format(data.worksite[key], "date");
            } else if (["ws_nowork"].includes(key)) {
              el.value = data.worksite[key] + "天";
            } else if (["ws_status"].includes(key)) {
              el.value = this.statusFilter(
                data.worksite[key],
                SelectOptions.SiteStatus
              );
            } else if (["ws_attendance"].includes(key)) {
              el.value = this.statusFilter(
                data.worksite[key],
                SelectOptions.SiteAttendanceType
              );
            } else {
              el.value = data.worksite[key];
            }

            if (["ws_name"].includes(key)) {
              this.record.ws_name = data.worksite[key];
            }
          });
        }
        if (data.log) {
          this.logInfo.forEach((el) => {
            let key = el.key;
            el.value = data.log[key];
          });
        }
      });
    },
    statusFilter(value, options) {
      const len = options.length;
      for (let i = 0; i < len; i++) {
        if (value == options[i].value) {
          return options[i].label;
        }
      }
      return "--";
    },
    JumpSubpage(record, showChild, title) {
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地" + title;
      this.record.ws_id = this.ws_id;
      this[showChild] = true;
      // this.formType = formType
    },
    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SiteSnapshot = false;
      this.SitePlayback = false;
      this.SiteAttend = false;
    },
    jumpBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
div.ant-drawer-body {
  background-color: white !important;
}
.v-container {
  // background: #0a1621;
  min-width: 800px;
  // height: 100%;
  overflow: auto;
}
.sub-container {
  min-height: 100%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  // color: #66ffff;
  // height: 75px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
  }
  .v-title-right {
    position: absolute;
    right: 30px;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
    &:hover {
      // color: #66ffff;
    }
  }
}
.info-title {
  // height: 42px;
}

.info-box {
  display: flex;
  height: 40px;
  line-height: 40px;
  // border: 1px solid black;
}
.info-box + .info-box {
  border-top: none;
}
.box-label {
  width: 100px;
  text-align: right;
  padding-right: 10px;
  // background-color: rgba(20, 134, 230, 0.23);
}
.box-value {
  flex: 1;
  text-align: left;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-tabs {
  // color: #fff;
  color: black;
  // background-color: #073f6f;
  margin-top: 16px;
  padding: 40px 20px 0 20px;
  border-radius: 5px;
  height: 96%;
}
.v-msg {
  width: 100%;
}
</style>
