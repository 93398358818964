<template>
  <div class="v-container">
    <div class="sub-container">
      <div class="v-title">
        <div class="v-title-left">{{ nowTime }}</div>
        工地巡查大屏
        <div class="v-title-right">
          <span @click="jumpBack"><a-icon type="logout" /> 返回</span>
        </div>
      </div>

      <div style="display: flex; margin-top: 20px">
        <a-icon class="a_icon" @click="changeWsId(-1)" type="left" />
        <div style="flex: 1; padding-right: 10px">
          <SitePreview
            v-if="SitePreview"
            :record="record"
            :pageType="'patrol'"
          ></SitePreview>
        </div>
        <a-icon class="a_icon" @click="changeWsId(+1)" type="right" />
        <!-- <div class="v-title" style="width: 30%;"> -->
        <div class="v-msg">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="工地信息">
              <div class="info-title">
                <div
                  class="v-btn"
                  @click="JumpSubpage({}, 'SiteAttend', '考勤')"
                >
                  考勤
                </div>
                <div
                  class="v-btn"
                  @click="JumpSubpage({}, 'SiteSnapshot', '快照')"
                >
                  快照
                </div>
                <div
                  class="v-btn"
                  @click="JumpSubpage({}, 'SitePlayback', '查看回放')"
                >
                  查看回放
                </div>
              </div>
              <div v-for="item in siteInfo" :key="item.key" class="info-box">
                <div class="box-label">{{ item.label }}</div>
                <div class="box-value">{{ item.value }}</div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="设备信息">
              <div class="deviseList">
                <div v-for="(item, index) in deviceList" :key="index">
                  <div @click="changed_id(item, index)" class="deviseList_name">
                    设备{{ item.d_imei }}
                  </div>
                </div>
              </div>
              <div v-for="item in deviceInfo" :key="item.key" class="info-box">
                <div class="box-label">{{ item.label }}</div>
                <div class="box-value">{{ item.value }}</div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <SiteSnapshot :record="record" v-if="SiteSnapshot"></SiteSnapshot>
      <SitePlayback :record="record" v-if="SitePlayback"></SitePlayback>
      <SiteAttend :record="record" v-if="SiteAttend"></SiteAttend>
    </a-drawer>
  </div>
</template>
<script>
import SitePreview from "@/views/site/SitePreview.vue";
import SiteSnapshot from "@/views/site/SiteSnapshot.vue";
import SitePlayback from "@/views/site/SitePlayback.vue";
import SiteAttend from "@/views/site/SiteAttend.vue";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetSiteInfo } from "@/api/patrol.js";
import { GetPatrolphoto } from "@/api/patrol";
import { urlConfig } from "@/config/config";
const orderParam = [
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "device_status",
  "imei",
  "bc_ids",
  "ws_ids",
  "w_gj_ids",
  "w_jl_ids",
  "ws_only",
  "e_events",
  "pageNo",
  "pageSize",
];
export default {
  name: "detail",
  components: { SitePreview, SiteSnapshot, SitePlayback, SiteAttend },
  data() {
    return {
      ws_id: "",
      deviceList: [],
      deviceInfo: [
        { label: "设备状态", key: "d_status", value: "--" },
        { label: "设备IMEI号", key: "d_imei", value: "--" },
        { label: "设备账号", key: "d_htxid", value: "--" },
        { label: "设备分配日期", key: "d_distribution_time", value: "--" },
        { label: "设备启用日期", key: "d_time", value: "--" },
        { label: "设备绑定人", key: "d_w_name", value: "--" },
        { label: "套餐剩余天数", key: "d_remain_day", value: "--" },
      ],
      siteInfo: [
        { label: "分公司", key: "ws_bc_name", value: "--" },
        { label: "工地名称", key: "ws_name", value: "--" },
        { label: "工程管家", key: "ws_gj", value: "--" },
        { label: "项目经理", key: "ws_jl", value: "--" },
        { label: "绑定时间", key: "ws_bind_time", value: "--" },
        { label: "无人施工天数", key: "ws_nowork", value: "--" },
        { label: "工地状态", key: "ws_status", value: "--" },
        { label: "打卡状态", key: "ws_attendance", value: "--" },
        { label: "打卡人数", key: "ws_a_sum", value: "--" },
      ],
      logInfo: [
        { label: "操作账号", key: "u_phone", value: "--" },
        { label: "岗位", key: "u_post", value: "--" },
        { label: "姓名", key: "u_name", value: "--" },
        { label: "描述", key: "u_msg", value: "--" },
        { label: "操作时间", key: "u_time", value: "--" },
      ],
      // 弹出框
      visible: false,
      title: "",
      record: {
        ws_name: "",
        ws_id: "",
        ws_model: "",
        w_power: "", //是否有工地权限修改样板工地
      },
      SiteAttend: false,
      SiteSnapshot: false,
      SitePlayback: false,
      timer: "", //定义一个定时器
      nowTime: "", // 当前日期时间
      list: [],
      index: "",
      SitePreview: true,
      totla: "",
      page: "",
      index1: "",
    };
  },
  created() {
    // console.log(this.$route.query);
    this.ws_id = this.$route.query.ws_id;
    this.fetchData();
    this.list = this.$route.query.list; //此页的数据
    this.list.forEach((item, index0) => {
      if (item.ws_id == this.ws_id) {
        this.index = index0;
      }
    });
    this.total = this.$route.query.total; //共几页
    this.page = this.$route.query.current; //第几页
  },
  mounted() {
    this.getTime();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    record(newVal) {
      console.log("record", newVal);
    },

    immediate: true,
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];

        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    fetchData() {
      let params = {
        ws_id: this.ws_id,
      };
      GetSiteInfo(params)
        .then((res) => {
          let data = res.data;
          // console.log("GetSiteInfo", data);
          this.record.ws_model = JSON.parse(
            JSON.stringify(data.worksite.ws_model)
          );
          this.record.w_power = data.worksite.w_power;
          if (data.device) {
            this.deviceList = data.device;
            let items = data.device[0];
            this.deviceInfo.forEach((el) => {
              let key = el.key;

              // 需要设备id
              this.record.d_id = items.d_id;

              // console.log("items", items);
              if (["d_distribution_time", "d_time"].includes(key)) {
                el.value = this.$Format(items[key], "date");
              } else if (["d_remain_day"].includes(key)) {
                el.value = this.$calcDate(items[key], "date");
              } else if (["d_status"].includes(key)) {
                el.value = this.statusFilter(
                  items[key],
                  SelectOptions.SiteDeviceStatus
                );
              } else {
                el.value = items[key];
              }
            });
          }
          if (data.worksite) {
            this.siteInfo.forEach((el) => {
              let key = el.key;
              if (["ws_bind_time"].includes(key)) {
                el.value = this.$Format(data.worksite[key], "date");
              } else if (["ws_nowork"].includes(key)) {
                el.value = data.worksite[key] + "天";
              } else if (["ws_status"].includes(key)) {
                el.value = this.statusFilter(
                  data.worksite[key],
                  SelectOptions.SiteStatus
                );
              } else if (["ws_attendance"].includes(key)) {
                el.value = this.statusFilter(
                  data.worksite[key],
                  SelectOptions.SiteAttendanceType
                );
              } else {
                el.value = data.worksite[key];
              }

              if (["ws_name"].includes(key)) {
                this.record.ws_name = data.worksite[key];
              }
              if (["ws_model"].includes(key)) {
                this.record.ws_model = data.worksite.ws_model;
              }
            });
          }
          if (data.log) {
            this.logInfo.forEach((el) => {
              let key = el.key;
              el.value = data.log[key];
            });
          }
        })
        .then(() => {});
    },
    statusFilter(value, options) {
      const len = options.length;
      for (let i = 0; i < len; i++) {
        if (value == options[i].value) {
          return options[i].label;
        }
      }
      return "--";
    },
    JumpSubpage(record, showChild, title) {
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地" + title;
      this.record.ws_id = this.ws_id;
      this[showChild] = true;
      // this.formType = formType
    },
    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SiteSnapshot = false;
      this.SitePlayback = false;
      this.SiteAttend = false;
    },
    jumpBack() {
      this.$router.go(-1);
    },
    changeWsId(num) {
      this.SitePreview = false;
      setTimeout(() => {
        // 当处于第一页时
        if (this.index == 0 && num < 0) {
          this.page = this.page * 1 - 1;
          // 当处于第一页第一条数据时
          if (this.page < 1) {
            this.$message.warning("没有更多数据了");
            this.SitePreview = true;
          } else {
            // 如果不是第一页的第一条数据，那就跳到上一页的最后一条数据
            console.log("-1", this.page);
            this.index = 5;
            this.search(this.page);
          }
        } else if (this.index == 5 && num > 0) {
          // 当处于某一页最后一条数据时
          this.page = this.page + 1;
          if (this.page > this.total) {
            // 当处于最后一页最后一条数据时
            this.$message.warning("没有更多数据了");
            this.SitePreview = true;
          } else {
            // 如果不是最后一页数据的最后一条数据，那就跳转到下一页的第一条数据
            console.log("+1", this.page);
            this.index = 0;
            this.search(this.page);
          }
        } else {
          // 既不是第一条数据也不是最后一条数据
          console.log("page", this.page);
          this.index = this.index + num;
          this.ws_id = this.list[this.index].ws_id;
          // console.log(this.ws_id); //拿到id
          this.record.ws_id = this.ws_id;
          this.fetchData();
          this.SitePreview = true;
        }
        localStorage.setItem("inspection_page", this.page);
      }, 10);
    },
    // 查询
    search(page) {
      // console.log(this.form);
      //  console.log(JSON.parse(localStorage.getItem("patrolParam")).form);
      let form = JSON.parse(localStorage.getItem("patrolParam")).form;
      const param = Object.assign({}, form, {
        pageNo: page, //第几页
        pageSize: 6,
      });
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      GetPatrolphoto(params)
        .then((res) => {
          let list = [];
          res.data.data.forEach((el) => {
            if (el.file_path.length > 0) {
              el.file_path =
                urlConfig.baseUrl +
                "/files/picture/snapshot/casepic?filePath=" +
                el.file_path;
            } else {
              el.file_path = require("../../assets/images/62456709df3e17415c6e2751d25f28a.png");
            }
          });
          list = res.data.data;
          this.list = list;
          console.log("this.list", this.list);
        })
        .then(() => {
          if (this.index == 5) {
            this.ws_id = this.list[5].ws_id;
            // console.log(this.ws_id); //拿到id
            console.log("-1 list", this.list);

            this.record.ws_id = this.ws_id;
            this.fetchData();
            this.SitePreview = true;
          } else if (this.index == 0) {
            this.ws_id = this.list[0].ws_id;
            // console.log(this.ws_id); //拿到id
            console.log("-1 list", this.list);
            this.record.ws_id = this.ws_id;
            this.fetchData();
            this.SitePreview = true;
          }
        });
    },
    // 改变设备号
    changed_id(e, index) {
      // console.log("changed_id", index, this.deviceList.length);
      // console.log("改变设备号", e);
      let items = this.deviceList[index];
      this.deviceInfo.forEach((el) => {
        let key = el.key;
        if (["d_distribution_time", "d_time"].includes(key)) {
          el.value = this.$Format(items[key], "date");
        } else if (["d_remain_day"].includes(key)) {
          el.value = this.$calcDate(items[key], "date");
        } else if (["d_status"].includes(key)) {
          el.value = this.statusFilter(
            items[key],
            SelectOptions.SiteDeviceStatus
          );
        } else {
          el.value = items[key];
        }
      });

      this.SitePreview = false;
      setTimeout(() => {
        // 需要设备id
        this.record.ws_id = this.ws_id;
        this.record.d_id = items.d_id;
        console.log("items", items, this.record);
        setTimeout(() => {
          this.SitePreview = true;
        }, 10);
      }, 10);
      // 点击的设备改变为蓝色字体
      let BGCblue = document.getElementsByClassName("deviseList_name");

      for (let index1 = 0; index1 < this.deviceList.length; index1++) {
        if (index1 != index) {
          BGCblue[index1].style.background = "#fff";
          BGCblue[index1].style.color = "#1486e6";
        } else if (index1 == index) {
          //被点击
          BGCblue[index1].style.color = "#fff";
          BGCblue[index1].style.background = "#1486e6";
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.v-container {
  background: #0a1621;
  min-width: 1200px;
  height: 100%;
  overflow: auto;
}
.sub-container {
  min-height: 100%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 75px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
  }
  .v-title-right {
    position: absolute;
    right: 30px;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
    &:hover {
      color: #66ffff;
    }
  }
}
.info-title {
  height: 42px;
}

.info-box {
  display: flex;
  min-height: 40px;
  line-height: 40px;

  border: 1px solid #1486e6;
}
.info-box + .info-box {
  border-top: none;
}
.box-label {
  width: 150px;
  text-align: right;
  padding-right: 10px;
  background-color: rgba(20, 134, 230, 0.23);
}
.box-value {
  flex: 1;
  text-align: left;
  padding-left: 10px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.ant-tabs {
  color: #fff;
  background-color: #073f6f;
  margin-top: 16px;
  padding: 40px 20px 0 20px;
  border-radius: 5px;
  height: 96%;
}
.v-msg {
  width: 30%;
}
.a_icon {
  font-size: 50px;
  display: flex;
  align-items: center;
  color: #fff;
}
.deviseList {
  display: flex;
  div {
    margin: 0 10px;
    cursor: pointer;
  }
  .deviseList_name {
    padding: 5px;
    border-radius: 5px;
    color: #1486e6;
    background: #fff;
    // color: #1486e6;
    // background-color: #cfe7fa;
    margin-bottom: 10px;
  }
}
</style>
