<!-- 详情 -->
<template>
  <div>
    <div class="modal-content attend-header">
      <div>
        <a-icon type="environment" theme="twoTone" class="model-site-name" />
        <span class="adressName">{{ record.ws_name }}</span>
      </div>
    </div>
    <div class="xinxi">
      <a-tabs
        default-active-key="1"
        @change="callback"
        type="card"
        :tabBarStyle="{ borderBottom: 'unset' }"
      >
        <a-tab-pane key="1" tab="基本信息" class="jibenxixin">
          <!-- 设备信息 -->
          <div>
            <div class="Mes1 xinxiName">设备信息</div>
            <a-table
              :columns="columns"
              :data-source="data"
              :scroll="{ x: 1100 }"
              rowKey="ws_new_photo"
              bordered
              :pagination="false"
            >
              <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
              <!-- <span slot="customTitle">设备名称</span> -->
              <span slot="ws_new_photo" slot-scope="text, record">
                {{ record.device_name }}
                <img :src="pathUrl + text" alt="" class="IMG" />
              </span>

              <span slot="device_status" slot-scope="text" class="Point_out">
                <span v-if="text == 1" style="color: green">●</span>
                <span v-else style="color: red">●</span>
                {{ text == 1 ? "在线" : "离线" }}
              </span>
              <span slot="device_distribution_time" slot-scope="text">
                {{ $Format(text) }}
              </span>
              <span slot="device_time" slot-scope="text">
                {{ $Format(text) }}
              </span>
              <span slot="d_remain_day" slot-scope="text">
                {{ $calcDate(text) }}
              </span>
            </a-table>
          </div>
          <!-- 工地信息 -->
          <div>
            <div class="Mes xinxiName">工地信息</div>
            <div>
              <div class="flex">
                <div class="flex">
                  <div class="flex_left">工地状态</div>
                  <div class="flex_right">{{ WsStatus }}</div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left">分公司</div>
                  <div class="flex_right">
                    {{ resData.ws_bc_name }}
                  </div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left">工程管家</div>
                  <div class="flex_right">
                    {{ resData.ws_gj_name }}{{ resData.ws_gj_phone }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex border_top">
                <div class="flex">
                  <div class="flex_left border_top">项目经理</div>
                  <div class="flex_right border_top">
                    {{ resData.ws_jl_name }}{{ resData.ws_jl_phone }}
                  </div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left border_top">绑定时间</div>
                  <div class="flex_right border_top">
                    {{ $Format(resData.ws_bind_time) }}
                  </div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left border_top">
                    累计无人施工总天数
                  </div>
                  <div class="flex_right border_top">
                    {{ resData.ws_nowork_sum }}天
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex">
                <div class="flex">
                  <div class="flex_left border_top">打卡状态</div>
                  <div class="flex_right border_top">
                    {{ resData.ws_attendance == 1 ? "已打卡" : "未打卡" }}
                  </div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left border_top">打卡人数</div>
                  <div class="flex_right border_top">
                    {{ resData.ws_attendance_num }}人
                  </div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left border_top">设备数量</div>
                  <div class="flex_right border_top">
                    {{ resData.ws_device_num }}个
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 其他信息 -->
          <div>
            <div class="Mes xinxiName">其他信息</div>
            <div>
              <div class="flex">
                <div class="flex">
                  <div class="flex_left">合同编号</div>
                  <div class="flex_right">{{ resData.ws_only }}</div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left"></div>
                  <div class="flex_right"></div>
                </div>
                <div class="flex">
                  <div class="flex_left border_left"></div>
                  <div class="flex_right"></div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="项目成员" force-render>
          <a-table
            :columns="columns1"
            :data-source="data1"
            rowKey="phone"
            bordered
          >
            <span slot="sex" slot-scope="text"
              >{{ text == 1 ? "男" : "女" }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="工地绑定设备记录">
          <div class="Mes1 xinxiName">
            绑定记录
            <span class="bangdingjilu">
              <a-tooltip
                placement="right"
                title="工地绑定设备历史记录"
                :get-popup-container="getPopupContainer"
              >
                <img src="../../assets/images/visualize/ti_shi.jpg" />
              </a-tooltip>
            </span>
          </div>
          <a-table
            :columns="columns2"
            :data-source="data2"
            bordered
            rowKey="index"
          >
            <span slot="bind_time" slot-scope="text">
              {{ text ? $Format(text) : "--" }}
            </span>
            <span slot="unbind_time" slot-scope="text">
              {{ text ? $Format(text) : "--" }}
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import {
  GetAttend,
  GetSiteCalendar,
  sitDetail,
  sitPeopledetail,
  siteDevicedetail,
} from "@/api/site";
import { urlConfig } from "@/config/config";

// import { ExportStopped } from "@/api/export";
// import TableMerge from "./AttendTable.vue";
// 基本信息表格
const columns = [
  {
    title: "设备名称",
    dataIndex: "ws_new_photo",
    width: "110px",
    key: "ws_new_photo",
    scopedSlots: { customRender: "ws_new_photo" },
    align: "center",
  },
  {
    title: "设备状态",
    dataIndex: "device_status",
    key: "device_status",
    scopedSlots: { customRender: "device_status" },
    width: "90px",
    align: "center",
  },
  {
    title: "设备IMEI号",
    dataIndex: "device_imei",
    key: "device_imei",
    width: "160px",
    align: "center",
  },
  {
    title: "设备所属组织",
    dataIndex: "bc_org",
    key: "bc_org",
    width: "160px",
    align: "center",
  },
  // {
  //   title: "设备账号",
  //   dataIndex: "device_htx",
  //   key: "device_htx",
  //   width: "160px",
  //   align: "center",
  // },

  {
    title: "设配分配日期",
    dataIndex: "device_distribution_time",
    key: "device_distribution_time",
    width: "150px",
    scopedSlots: { customRender: "device_distribution_time" },
    align: "center",
  },
  {
    title: "设备启用日期",
    dataIndex: "device_time",
    key: "device_time",
    width: "150px",
    scopedSlots: { customRender: "device_time" },
    align: "center",
  },
  {
    title: "设备绑定人",
    dataIndex: "device_bind_name",
    key: "device_bind_name",
    width: "150px",
    align: "center",
  },
  {
    title: "套餐剩余天数",
    dataIndex: "d_remain_day",
    key: "d_remain_day",
    width: "130px",
    scopedSlots: { customRender: "d_remain_day" },
    align: "center",
  },
];

const data = [
  {
    key: "1",
    device_name: "",
    device_imei: "设备imei号",
    device_htx: "设备账号",
    device_status: "在离线",
    device_distribution_time: "分配时间",
    device_time: "启用时间",
    device_bind_name: "设备绑定人",
    d_remain_day: "套餐剩余天数",
  },
];
// 项目成员表格
const columns1 = [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "岗位/工种",
    dataIndex: "post",
    key: "post",
    align: "center",
  },
  // {
  //   title: "工种",
  //   dataIndex: "type",
  //   key: "type",
  //   align: "center",
  // },

  {
    title: "性别",
    dataIndex: "sex",
    key: "sex",
    scopedSlots: { customRender: "sex" },
    align: "center",
  },
  {
    title: "联系方式",
    dataIndex: "phone",

    key: "phone",
    align: "center",
  },
  {
    title: "所属公司",
    dataIndex: "bc_name",
    key: "bc_name",
    align: "center",
  },
];
const data1 = [
  {
    key: "1",
    name: "姓名",
    post: "岗位",
    type: "工种",
    sex: "性别",
    phone: "联系方式",
    bc_name: "所属公司",
  },
];
// 工地绑定信息记录表格
const columns2 = [
  {
    title: "设备IMEI号",
    dataIndex: "imei",
    with: "80px",
    key: "imei",
    align: "center",
  },
  {
    title: "设备绑定人",
    dataIndex: "bind_name",
    with: "40px",
    key: "bind_name",
    align: "center",
  },
  {
    title: "绑定时间",
    dataIndex: "bind_time",
    scopedSlots: { customRender: "bind_time" },
    with: "80px",
    key: "bind_time",
    align: "center",
  },

  {
    title: "设备解绑人",
    dataIndex: "unbind_name",
    with: "80px",
    key: "unbind_name",
    align: "center",
  },
  {
    title: "解绑时间",
    dataIndex: "unbind_time",
    scopedSlots: { customRender: "unbind_time" },
    with: "80px",
    key: "unbind_time",
    align: "center",
  },
];
const data2 = [
  {
    key: "1",
    imei: "设备IMEI号",
    bind_name: "设备绑定人",
    bind_time: "",
    unbind_name: "设备解绑人",
    unbind_time: "",
  },
];
const orderParam = ["ws_id"];
export default {
  name: "SiteParticulars",
  components: {
    // TableMerge,
  },
  props: {
    record: {
      type: Object,
      default: {
        ws_name: "",
      },
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      // 查询参数
      queryParam: {},
      tableLoading: false,
      attendData: {},
      resData: {},
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      sitPeopledetail: "",
      siteDevicedetail: "",
      WsStatus: "", //工地状态
      // 日历
      calendarData: [],
      data,
      columns,
      data1,
      columns1,
      data2,
      columns2,
    };
  },
  created() {
    // this.queryParam.ws_id = this.record.ws_id;
    // this.siteName = this.$route.query.name;
    // this.fetchAttend();
    // this.initCalendar();
    // console.log(this.record.ws_id);
    this.getsitDetail();
    this.getSitPeopledetail();
    this.getSiteDevicedetail();
  },
  methods: {
    callback(key) {
      // console.log(key);
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    // fetchAttend() {
    //   this.tableLoading = true;
    //   GetAttend(this.queryParam).then((res) => {
    //     // 处理数据
    //     this.tableLoading = false;
    //     this.attendData = res.data;
    //   });
    // },
    // initCalendar() {
    //   let params = {
    //     ws_id: this.record.ws_id,
    //   };
    //   GetSiteCalendar(params).then((res) => {
    //     this.calendarData = res.data;
    //   });
    // },
    onPanelChange(value, mode) {
      console.log(value, mode);
    },
    handleExport() {
      this.visible = true;
    },
    handleOk() {
      this.confirmLoading = true;
    },
    handleCancel() {
      this.visible = false;
    },
    // 获取工地详细信息接口
    getsitDetail() {
      const param = Object.assign({}, { ws_id: this.record.ws_id });
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      return sitDetail(params).then((res) => {
        console.log(res.data);
        this.resData = res.data;
        let ws_device_detail = this.resData.ws_device_detail;
        // data[0].device_name = this.pathUrl + ws_device_detail[0].ws_new_photo;
        this.data = ws_device_detail;

        if (res.data.ws_status == 1) {
          this.WsStatus = "施工中";
        } else if (res.data.ws_status == 2) {
          this.WsStatus = "未开工";
        } else if (res.data.ws_status == 3) {
          this.WsStatus = "合同终止";
        } else if (res.data.ws_status == 4) {
          this.WsStatus = "施工结束";
        } else {
          this.WsStatus = "空";
        }
      });
    },
    //工地项目成员信息接口
    getSitPeopledetail() {
      const param = Object.assign({}, { ws_id: this.record.ws_id });
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return sitPeopledetail(params).then((res) => {
        this.data1 = res.data;
        // console.log(this.data1);
      });
    },
    //工地设备记录接口
    getSiteDevicedetail() {
      const param = Object.assign({}, { ws_id: this.record.ws_id });
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return siteDevicedetail(params).then((res) => {
        this.data2 = res.data;
        // console.log(res.data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
div {
  background-color: #fff;
}
.attend-header {
  display: flex;
  justify-content: space-between;
  // justify-content: center;
  // padding-right: 20px;
}
.events {
  color: #fff;
  padding: 0 5px;
  background-color: #1890ff;
}
.flex {
  display: flex;
  // justify-content: center;

  line-height: 20px;
}
.flex_left {
  width: 100px;
  background-color: #fafafa;
  padding: 10px;
  // line-height: 40px;
  border: 1px solid #e8e8e8;
  // text-align: center;
  display: flex;
  align-items: center;
}

.flex_right {
  width: 155px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-left: none;
  display: flex;
  align-items: center;
}
.juzhong {
  // line-height: 40px;
  display: flex;
  align-items: center;
  // background-color: pink;
}
.border_left {
  border-left: none;
}
.border_top {
  border-top: none;
}
.Mes1 {
  margin-bottom: 10px;
}
.Mes {
  margin-top: 20px;
  margin-bottom: 10px;
}

.bangdingjilu {
  img {
    // margin-left: -2px;
    margin-top: -4px;
    overflow: hidden;
    width: 15px;
    height: 15px;
  }
}

.IMG {
  width: 90px;
  height: 90px;
  padding: 0;
  margin: 0;
}
.Point_out {
  display: flex;
  justify-content: center;
  line-height: 6px;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-left: 10px;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border: 1px solid #1890ff;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 0;
  height: 50px;
  line-height: 30px;
  // width: 37%;
  width: 248px;
  padding: 8px 0;
  text-align: center;
  background: none;
}
/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 50px;
}
.adressName {
  font-size: 20px;
  font-weight: 600;
}
.model-site-name {
  font-size: 15px;
}
.xinxiName {
  margin-left: 10px;
  font-weight: 600;
}
</style>
