<!-- 回放 -->
<template>
  <div @click.stop>
    <div class="modal-content" @click="NoshowCalendar">
      <div class="siteName">工地名称：{{ record.ws_name }}</div>
      <div class="deviseList">
        <div v-for="(item, index) in ws_bind_d_ids" :key="index">
          <div @click="changed_id(item, index)" class="deviseList_name">
            设备{{ index + 1 }}
          </div>
          <!-- <div @click="changed_id(item, index)" class="deviseList_name">
            设备{{ item }}
          </div> -->
        </div>
      </div>
      <span>日期：</span>
      <a-input
        v-model="dataTime"
        placeholder="请选择日期"
        @focus="showCalendar"
        class="calendar_input"
      />
      <a-calendar
        v-model="dataTime"
        :fullscreen="false"
        :disabled-date="disabledDate"
        @change="onChangeDate"
        class="model-site-input calendar_rili"
        v-show="isshowCalendar"
      >
        <template slot="dateCellRender" slot-scope="value">
          <!-- <span v-if="showSpot(value)">
            <a-badge status="error" />
          </span> -->
          <span v-if="showSpot_lv(value)">
            <a-badge status="success" />
          </span>
        </template>
      </a-calendar>
      <!-- </a-date-picker> -->

      <!-- <br /> -->
      <!-- <a-icon
        type="environment"
        theme="twoTone"
        class="model-site-name"
        style="margin-top: 16px"
      />

      <span>{{ record.ws_name }}</span> -->
    </div>
    <div class="modal-content">
      <div v-if="list.length > 0" class="haveVideo">
        <div class="haveVideo_left">
          <div style="height: 20px; width: 100%; color: #1890ff">
            {{ showName ? (showVideo ? nowplayname : "正在请求视频") : "" }}
            {{ GBshowVideo ? nowplayname : "" }}
          </div>
          <video
            v-if="showVideo"
            controls
            width="100%"
            height="300px"
            id="video"
            autoplay
            preload="auto"
            :src="video.play"
            controlslist="nodownload"
          >
            <source :src="video.play" type="video/mp4" />
          </video>
          <video
            v-if="GBshowVideo"
            width="100%"
            height="300px"
            id="GBvideo"
            autoplay
            controls
            crossorigin="*"
            @canplay="getVidDur"
          ></video>
          <div v-show="GBshowVideo" class="videoPlayTime">
            {{ GBvideoPlayTime }}
          </div>
          <!-- 按钮 -->
          <div v-show="showVideo || GBshowVideo" class="speed-btn">
            <!-- 暂停静音全屏按钮 -->
            <div class="playBTN">
              <div
                class="v-btn"
                v-show="bofang == 1 ? true : false"
                @click="bofangPlay('bofang')"
              >
                <a-icon type="caret-right" />
              </div>
              <div
                class="v-btn"
                v-show="bofang == 2 ? true : false"
                @click="bofangPlay('zanting')"
              >
                <a-icon type="pause" />
              </div>

              <!-- 静音/取消静音按钮 -->
              <div
                class="v-btn"
                v-show="jingyin == 1 ? true : false"
                @click="handelPlay('jingyin')"
              >
                <!-- 静音 -->
                <img
                  src="../../assets/images/site_img/quxiaojingyin.png"
                  height="16px"
                />
              </div>
              <div
                class="v-btn"
                v-show="jingyin == 2 ? true : false"
                @click="handelPlay('quxiaojingyin')"
              >
                <!-- 取消静音 -->

                <img
                  src="../../assets/images/site_img/jingyin.png"
                  height="20px"
                />
              </div>

              <!-- <a-button type="primary" ghost @click="bofangRate()">
              <a-icon type="forward" />播放速度
            </a-button> -->
              <!-- 全屏按钮 -->
              <div class="v-btn quanping" @click="quanping()">
                <a-icon type="fullscreen" />
              </div>
            </div>
            <!-- 前进后退10秒 -->
            <div class="speedBTN">
              <a-tooltip style="outline: none">
                <template slot="title"> 后退10秒 </template>
                <svg
                  @click="speed('back')"
                  @mouseover="aftermouseOver"
                  @mouseleave="aftermouseLeave"
                  t="1644822468201"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1267"
                  width="45"
                  height="45"
                >
                  <path
                    d="M656.9 680.5l-250-144.3c-17.7-10.2-17.7-35.7 0-45.9L656.8 346c17.7-10.2 39.8 2.6 39.8 23v288.6c0 20.3-22.1 33.1-39.7 22.9z"
                    :fill="afterplayBackground2"
                    p-id="1268"
                  ></path>
                  <path
                    d="M531.3 680.5L281.4 536.2c-17.7-10.2-17.7-35.7 0-45.9L531.3 346c17.7-10.2 39.8 2.6 39.8 23v288.6c0 20.3-22.1 33.1-39.8 22.9z"
                    :fill="afterplayBackground1"
                    p-id="1269"
                  ></path>
                  <path
                    d="M514 912c-219.9 0-398.8-178.9-398.8-398.8S294.1 114.3 514 114.3s398.9 178.9 398.9 398.9C912.8 733.1 733.9 912 514 912z m0-701.5c-166.9 0-302.7 135.8-302.7 302.7S347.1 815.9 514 815.9s302.7-135.8 302.7-302.7S680.9 210.5 514 210.5z"
                    :fill="afterplayBackground2"
                    p-id="1270"
                  ></path>
                </svg>
              </a-tooltip>
              <a-tooltip style="outline: none">
                <template slot="title"> 前进10秒 </template>
                <svg
                  @click="speed('forward')"
                  @mouseover="frontmouseOver"
                  @mouseleave="frontmouseLeave"
                  t="1644821480551"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2474"
                  width="45"
                  height="45"
                >
                  <path
                    d="M371.1 345.9L621 490.2c17.7 10.2 17.7 35.7 0 45.9L371.1 680.5c-17.7 10.2-39.8-2.6-39.8-23V368.9c0-20.4 22.2-33.2 39.8-23z"
                    :fill="frontplayBackground2"
                    p-id="2475"
                  ></path>
                  <path
                    d="M496.7 345.9l249.9 144.3c17.7 10.2 17.7 35.7 0 45.9L496.7 680.5c-17.7 10.2-39.8-2.6-39.8-23V368.9c0-20.4 22.1-33.2 39.8-23z"
                    :fill="frontplayBackground1"
                    p-id="2476"
                  ></path>
                  <path
                    d="M514 912c-219.9 0-398.8-178.9-398.8-398.9 0-219.9 178.9-398.8 398.8-398.8s398.9 178.9 398.9 398.8c-0.1 220-179 398.9-398.9 398.9z m0-701.5c-166.9 0-302.7 135.8-302.7 302.7S347.1 815.9 514 815.9s302.7-135.8 302.7-302.7S680.9 210.5 514 210.5z"
                    :fill="frontplayBackground2"
                    p-id="2477"
                  ></path>
                </svg>
              </a-tooltip>
            </div>
            <div class="playBTN" v-show="!GBshowVideo">
              <div class="v-btn downPlay" @click="downPlay()">
                <a-tooltip>
                  <template slot="title"> 下载 </template> 下载</a-tooltip
                >
              </div>
              *下载当前视频
            </div>
            <!-- <a-button @click="downPlay()" style="margin-left: 10px;">下载</a-button> -->
          </div>
        </div>
        <div class="haveVideo_right">
          <div class="videoListLength">共{{ videoList.length }}个视频</div>
          <div class="border" v-if="showVideo">
            <div
              v-for="(item, index) in videoList"
              :key="item.p_id"
              class="pointer"
            >
              <div @click="playVideo(item, index)" class="p_name_date">
                <p>
                  {{
                    $Format(item.p_name[0], "date") +
                    " " +
                    $Format(item.p_name[0], "times")
                  }}
                </p>
                <p class="p_name_time">
                  &nbsp;
                  {{ Math.trunc((item.p_name[1] - item.p_name[0]) / 60) }}分钟{{
                    Math.trunc(
                      (((item.p_name[1] - item.p_name[0]) / 60).toFixed(2) %
                        1) *
                        60
                    )
                  }}秒
                </p>

                <p>{{ item.p_isfile == "1" ? "@" : "" }}</p>
              </div>
            </div>
          </div>
          <div class="border" v-if="GBshowVideo">
            <div
              v-for="(item, index) in videoList"
              :key="item.startTime"
              class="pointer"
            >
              <div @click="ChangeVideo(item, index)" class="p_name_date">
                <p>{{ item.startTime }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length == 0" class="NoVideo">暂无视频播放</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import {
  GetEvent,
  PlaybackStart,
  PlaybackIndex,
  Playback,
  GBPlaybackQuery,
  GetWsbinddevices,
  GBPlaybackStart,
  GBPlaybackStop,
  GBPlaybackPause,
  GBPlaybackResume,
  GBLiveRecord,
  GBPlaybackSeek,
} from "@/api/video";
import { urlConfig } from "@/config/config";

// const onTimeUpdate = (event) => {
//   // event.target 是触发事件的 video 元素
//   const playedSeconds = event.target.currentTime;
//   console.log(`已播放: ${playedSeconds} 秒`);
// };
// const GBvideoDom = document.getElementById("GBvideo");
const GBvideo = () => {
  var GBvideoDom = document.getElementById("GBvideo");
  // console.log("视频时长", GBvideoDom.duration);
  // console.log("视频当前播放时间", GBvideoDom.currentTime);
  return GBvideoDom;
};

export default {
  name: "SitePlayback",
  props: {
    record: {
      type: Object,
      default: {
        w_id: "",
        ws_id: "",
        ws_name: "",
      },
    },
  },
  data() {
    return {
      dateList: [],
      dateList1: [],
      dataTime: "",
      events: [
        { e_id: "all", e_name: "全部" },
        { e_id: "bad", e_name: "不整洁" },
        { e_id: "cameraproblem", e_name: "摄像头歪曲" },
        { e_id: "noclothes", e_name: "未穿工服" },
        { e_id: "smoking", e_name: "有人吸烟" },
      ],
      queryParam: {
        nowork: "all",
        ws_id: "", // 工地视频回放id
        ws_time: "", // 查看时间戳
        ws_time1: new Date(new Date().setHours(0, 0, 0, 0)) / 1000, // 查看时间戳
        w_id: "", // 工地id
        type: "",
      },
      showVideo: false,
      showName: false,
      isshowCalendar: false,
      video: {
        play: "",
      },
      date: "", //默认时间
      time1: "",
      time2: "",
      dateFormat: "YYYY-MM-DD",
      morenDate: "",
      i: -1,
      j: -1,
      list: [],
      shijianchuo: new Date(new Date().setHours(0, 0, 0, 0)) / 1000,
      videoList: [],
      cycleMax: 60,
      cycleTime: 1,
      timer: null, // 定时器
      // 推流
      streamer: {},
      //当前视频文件的名字
      nowplayname: "",
      //快退
      afterplayBackground1: "#333333",
      afterplayBackground2: "#d9d9d9",
      //快进
      frontplayBackground1: "#333333",
      frontplayBackground2: "#d9d9d9",
      //下载
      downplayBackground1: "#333333",
      downplayBackground2: "#d9d9d9",
      jingyin: 1,
      bofang: 2,
      ws_timeIndex: new Date(new Date().setHours(0, 0, 0, 0)) / 1000,
      startValue: null,
      endValue: null,
      endOpen: false,
      ws_bind_d_ids: [],
      d_id: "",
      wsbinddevices: [],
      GBshowVideo: false,
      GBVideoValue: {},
      GBVideoImei: "",
      player: null,
      videoCurrentTime: "",
      GBvideoPlayTime: "00:01", //写GB28181直播当前播放时间
      GetGBvideoPlayTimeSetInterval: null,
      M: 0,
      S: 1,
      GBvideoDuration: 0,
    };
  },
  created() {
    console.log("this.record", this.record);
    this.queryParam.ws_id = this.record.ws_id;
    this.ws_bind_d_ids = this.record.ws_bind_d_ids.split(",");
    this.ws_bind_d_ids = this.ws_bind_d_ids.slice(
      0,
      this.ws_bind_d_ids.length - 1
    );
    this.queryParam.d_id = this.ws_bind_d_ids[0];
    // 查询设备绑定信息
    this.GetWsbinddevices(this.queryParam.ws_id);
  },
  mounted() {
    // this.startMoren();
    // this.getdateList();
    // 默认播放最近一天有回放视频的回放视频

    // this.onChangeDate();

    // console.log(this.$moment().subtract(30, "days").format("YYYY-MM-DD"));
    document.addEventListener("click", this.bodyCloseMenus);
    this.startValue = moment(Date.parse(new Date()) - 1000 * 60 * 60 * 24 * 7);
    this.endValue = moment(new Date());
    // 默认播放的第一个设备为蓝色字体
    setTimeout(() => {
      let BGCblue = document.getElementsByClassName("deviseList_name");
      // BGCblue[0].style.color = "#1890ff";
      //被点击
      BGCblue[0].style.color = "#cfe7fa";
      BGCblue[0].style.background = "#1486e6";
      // 默认播放第一条视频
      let p_name_date = document.getElementsByClassName("p_name_date");
      p_name_date[0].style.color = "#1890ff";
    }, 1000);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
  watch: {
    startValue(val) {},
    endValue(val) {},
    GBvideoPlayTime(val) {
      // console.log("GBvideoPlayTime---val", val);
    },
  },
  methods: {
    moment,
    showSpot(value) {
      let flag;
      this.dateList.map((date) => {
        if (date == value.format("YYYY-MM-DD")) {
          flag = true;
        }
      });
      return flag;
    },
    // 绿点   有回放视频
    showSpot_lv(value) {
      let flag;
      this.dateList1.map((date) => {
        if (date == value.format("YYYY-MM-DD")) {
          flag = true;
        }
      });
      return flag;
    },
    // 点击空白处关闭日历组件弹窗
    bodyCloseMenus(e) {
      let self = this;
      if (self.isshowCalendar == true) {
        self.isshowCalendar = false;
      }
    },
    // 选择日期只能选之前的时间
    disabledDate(current) {
      return current && current >= this.$moment().endOf("day");
      // return (
      //   current < this.$moment().subtract(7, "days") ||
      //   current >= this.$moment().endOf("day")
      // );
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },

    onChangeDate(date, dateString) {
      const d = new Date(this.dataTime._d);
      const resDate =
        d.getFullYear() +
        "-" +
        this.p(d.getMonth() + 1) +
        "-" +
        this.p(d.getDate());

      this.dataTime = resDate;
      let resDate1 = new Date(resDate).getTime() / 1000;
      // console.log("date", date, dateString);

      // console.log("resDate1", resDate1);

      let today = new Date().getTime();
      this.queryParam.ws_time = this.dataTime
        ? parseInt(new Date(this.dataTime).getTime() / 1000)
        : parseInt(resDate1);
      // this.queryParam.ws_time = resDate1;
      // console.log(this.queryParam.ws_time);
      // this.start();
      // this.isshowCalendar = false;
      // this.queryParam.d_id
      let index1 = -1;
      this.wsbinddevices.forEach((el, index) => {
        if (el.did == this.queryParam.d_id) {
          index1 = index;
        }
      });
      if (this.wsbinddevices[index1].dplatform == "gbs") {
        this.GBPlaybackStop();
        this.GetGBPlaybackQuery(this.wsbinddevices[index1].dimei, resDate1);
        this.isshowCalendar = false;
      } else {
        this.start();
        this.isshowCalendar = false;
      }
    },
    // 获取需要加红点或蓝点
    getdateList() {
      let params = {
        ws_id: this.queryParam.ws_id,
        d_id: this.queryParam.d_id,
        ws_time: this.queryParam.ws_time1,
      };
      PlaybackStart(params).then((res) => {
        setTimeout(() => {
          // console.log(this.queryParam.ws_time1);
          PlaybackIndex(params).then((res) => {
            // console.log(res);
            // let i = 0;
            if (this.j < 8) {
              // console.log("77", this.j);
              this.j = this.j + 1;
              var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
              // 一天是86400秒   故 7 天前的时间戳为
              var SevenDayAgo = timeStamp - 86400 * this.j;
              this.queryParam.ws_time1 = SevenDayAgo;
              // this.j = this.j + 1;
              // this.shijianchuo = SevenDayAgo;
              if (res.code == "400") {
                // console.log(res);
                let aaa = this.timestampToTime(res.msg * 1000, 0);
                this.dateList.push(aaa.replace(" ", ""));
              } else {
                let bbb = this.timestampToTime(res.msg * 1000, 0);
                this.dateList1.push(bbb.replace(" ", ""));
              }
              this.getdateList();
            }
          });
        }, 100);
      });
    },
    showCalendar() {
      console.log(" this.isshowCalendar", this.isshowCalendar);
      this.isshowCalendar = true;
    },
    NoshowCalendar() {
      // this.isshowCalendar = false;
    },
    fetchEvent() {
      let params = {
        ws_id: this.queryParam.ws_id,
      };
      GetEvent(params).then((res) => {});
    },
    // 获取前i天的日期
    timestampToTime(timestamp, i) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate() - i + "";
      return Y + M + D;
    },
    //  将日期转化为时间戳格式 获取前一天的时间戳--time1
    getshijianchuo(time, i) {
      var time = new Date(this.timestampToTime(Date.parse(new Date()), i));
      var time1 = time.getTime();
      return time1;
    },
    // 七天内最近一天有回放视频的日期
    getHuifangVideo() {
      let params = {
        ws_id: this.queryParam.ws_id,
        // ws_time: this.shijianchuo,
        d_id: this.queryParam.d_id,
        ws_time: this.ws_timeIndex,
      };
      PlaybackIndex(params).then((res) => {
        if (res.code == "200") {
          console.log("aaa" + res.code);
          this.list = [];
          res.data.forEach((el) => {
            this.list.push({
              p_id: el.p_id,
              p_name: el.p_name.split("_"),
              status: "0",
            });
          });
          this.showVideo = true;
          this.videoList = this.list;
          this.dataTime = this.timestampToTime(res.msg * 1000, 0);
          this.playVideo(this.videoList[0], 0);
        } else {
          if (this.i < 7) {
            this.i = this.i + 1;
            var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
            // // 一天是86400秒   故 7 天前的时间戳为
            var SevenDayAgo = timeStamp - 86400 * this.i;
            this.queryParam.ws_time = SevenDayAgo;
            let shijianchuo = SevenDayAgo;
            this.ws_timeIndex = shijianchuo;
            // this.getHuifangVideo();
            this.startMoren();
          } else {
            this.list = [];
          }
        }
      });
    },
    getDateTime(time) {
      console.log(this.morenDate);
      return this.morenDate
        ? moment(this.morenDate, "YYYY-MM-DD")
        : "2022-12-18";
    },
    startMoren() {
      let params = {
        ws_id: this.queryParam.ws_id,
        // ws_time: this.queryParam.ws_time,
        d_id: this.queryParam.d_id,
        ws_time: this.shijianchuo,
      };
      PlaybackStart(params).then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            // this.fetchIndex();
            this.getHuifangVideo();
          }, 1000);
        }
      });
    },

    start() {
      let params = {
        ws_id: this.queryParam.ws_id,
        d_id: this.queryParam.d_id,
        ws_time: this.queryParam.ws_time,
      };
      PlaybackStart(params).then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            this.fetchIndex();
          }, 1000);
        }
      });
    },
    fetchIndex() {
      let params = {
        ws_id: this.queryParam.ws_id,
        d_id: this.queryParam.d_id,
        ws_time: this.queryParam.ws_time,
      };
      PlaybackIndex(params).then((res) => {
        this.list = [];
        // console.log(res.code);
        if (res.code == "200") {
          res.data.forEach((el) => {
            this.list.push({
              p_id: el.p_id,
              p_name: el.p_name.split("_"),
              status: "0",
              p_isfile: el.p_isfile,
            });
          });
          this.showVideo = true;
          this.videoList = this.list;

          this.playVideo(this.videoList[0], 0);
        } else {
          this.$message.warn("没有视频！");
          this.list = [];
        }
      });
    },
    // 播放第几段视频
    playVideo(item, index) {
      window.clearTimeout(this.timer);

      this.showName = true;
      this.nowplayname =
        moment(item.p_name[0] * 1000).format("YYYY年MM月DD日 HH时mm分ss秒") +
        "—" +
        moment(item.p_name[1] * 1000).format("YYYY年MM月DD日 HH时mm分ss秒");
      // this.showVideo = false;
      let params = {
        w_id: this.record.ws_id,
        ws_id: item.p_id,
        ws_time: "",
        type: "2",
        fileexit: "2",
        d_id: this.queryParam.d_id,
      };
      Playback(params)
        .then((res) => {
          if (
            res === null ||
            res === "视频正在上传" ||
            res === "视频正在下载"
          ) {
            // this.showVideo = false;
            if (this.cycleTime < this.cycleMax) {
              this.timer = setTimeout(() => {
                this.playVideo(item);
                this.cycleTime++;
              }, 5000);
            } else {
              this.$message.warn("获取视频超时");
            }
          } else {
            console.log("请求到视频流");
            this.showVideo = true;
            window.clearTimeout(this.timer);
            this.video.play =
              urlConfig.baseUrl +
              `/files/api/worksite/playback?w_id=${params.w_id}&ws_id=${params.ws_id}&ws_time=${params.ws_time}&type=${params.type}&fileexit=1`;
          }
        })
        .then(() => {
          // console.log(index);this.videoList.length
          for (let index1 = 0; index1 < this.videoList.length; index1++) {
            let p_name_date = document.getElementsByClassName("p_name_date");
            // p_name_date[index1].style.color = "#fffff";

            if (index1 != index) {
              p_name_date[index1].style.color = "#000";
              // console.log("black");
            } else {
              p_name_date[index].style.color = "#1890ff";
              // console.log("blue");
            }
            // console.log(p_name_date[index1]);
          }
          // setTimeout(() => {
          //   let p_name_date = document.getElementsByClassName("p_name_date");
          //   p_name_date[index].style.color = "#1890ff";
          // }, 100);
        });
    },
    // 快进/快退
    speed(type) {
      let Media = document.getElementById("video");
      let time = 10; //单位秒，每次增减10秒
      // console.log("this.GBVideoValue.dplatform", this.GBVideoValue);
      let GBMedia = document.getElementById("GBvideo");
      console.log("GBMedia", GBMedia);
      let index1 = -1;
      this.wsbinddevices.forEach((el, index) => {
        if (el.did == this.queryParam.d_id) {
          index1 = index;
        }
      });
      if (this.wsbinddevices[index1].dplatform == "gbs") {
        // console.log("GBvideoDom", GBvideo());
        // let GBvideoDom = GBvideo();

        if (type === "back") {
          if (this.S < 10) {
            if (this.M < 1) {
              this.S = 0;
            } else {
              this.M = this.M - 1;
              this.S = this.S + 50;
            }
          } else {
            this.S = this.S - 10;
          }
          this.GBPlaybackSeek(-10);
          // GBMedia.currentTime !== 0 ? (GBMedia.currentTime -= time) : 1;
        } else if (type === "forward") {
          if (this.S > 50) {
            this.M = this.M + 1;
            this.S = this.S - 50 + 1;
          } else {
            this.S = this.S + 10;
          }
          // GBMedia.currentTime = GBMedia.currentTime * 1 + time * 1;
          this.GBPlaybackSeek(10);
        }
        console.log("  GBMedia.currentTime", GBMedia.currentTime, type);
      } else {
        if (type === "back") {
          Media.currentTime !== 0 ? (Media.currentTime -= time) : 1;
        } else if (type === "forward") {
          Media.volume !== Media.duration ? (Media.currentTime += time) : 1;
        }
      }
    },

    aftermouseOver() {
      this.afterplayBackground1 = "#2867CE";
      this.afterplayBackground2 = "#BDD2EF";
    },
    aftermouseLeave() {
      this.afterplayBackground1 = "#333333";
      this.afterplayBackground2 = "#d9d9d9";
    },
    frontmouseOver() {
      this.frontplayBackground1 = "#2867CE";
      this.frontplayBackground2 = "#BDD2EF";
    },
    frontmouseLeave() {
      this.frontplayBackground1 = "#333333";
      this.frontplayBackground2 = "#d9d9d9";
    },
    downmouseOver() {
      this.downplayBackground1 = "#2867CE";
      this.downplayBackground2 = "#BDD2EF";
    },
    downmouseLeave() {
      this.downplayBackground1 = "#333333";
      this.downplayBackground2 = "#d9d9d9";
    },
    //视频下载
    downPlay() {
      axios
        .post(
          this.video.play,
          {
            //Range: bytes=9469952-
            //'data':this.data//请求数据
          },
          {
            responseType: "blob", //服务器返回的数据类型
          }
        )
        .then((res) => {
          //Content-Length: 1067887
          //Content-Range: bytes 12550144-13618030/13618031

          //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
          //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
          //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
          const content = res.data;
          const blob = new Blob([content]); //构造一个blob对象来处理数据
          const fileName = this.nowplayname + ".mp4";

          //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
          //IE10以上支持blob但是依然不支持download
          if ("download" in document.createElement("a")) {
            //支持a标签download的浏览器
            const link = document.createElement("a"); //创建a标签
            link.download = fileName; //a标签添加属性
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click(); //执行下载
            URL.revokeObjectURL(link.href); //释放url
            document.body.removeChild(link); //释放标签
          } else {
            //其他浏览器
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭
    close() {
      this.cycleTime = this.cycleMax;
      window.clearTimeout(this.timer);
      this.GBPlaybackStop();
      clearInterval(this.GetGBvideoPlayTimeSetInterval);
      this.GetGBvideoPlayTimeSetInterval = null;
    },
    // 静音/取消静音按钮
    handelPlay(type) {
      // this.$refs.videoPlayer.play(); // 会触发videoPlay()函数
      let jingyin = document.getElementById("video");
      let GBjingyin = document.getElementById("GBvideo");
      if (jingyin) {
        if (type == "jingyin") {
          jingyin.muted = true;
          this.jingyin = 2;
          console.log("静音");
        } else if (type == "quxiaojingyin") {
          jingyin.muted = false;
          this.jingyin = 1;
          console.log("取消静音");
        }
      } else if (GBjingyin) {
        if (type == "jingyin") {
          GBjingyin.muted = true;
          this.jingyin = 2;
          console.log("静音");
        } else if (type == "quxiaojingyin") {
          GBjingyin.muted = false;
          this.jingyin = 1;
          console.log("取消静音");
        }
      }
    },
    // 暂停播放按钮
    bofangPlay(type) {
      let bofang = null;
      if (this.showVideo) {
        bofang = document.getElementById("video");
        if (type == "bofang") {
          bofang.play();
          this.bofang = 2;
        } else if (type == "zanting") {
          bofang.pause();
          this.bofang = 1;
        }
      } else if (this.GBshowVideo) {
        // bofang = document.getElementById("GBvideo");
        // console.log("bofang", bofang);

        if (type == "bofang") {
          this.GBPlaybackResume();

          this.bofang = 2;
        } else if (type == "zanting") {
          console.log("暂停getVidDur", this.getVidDur());

          this.GBPlaybackPause();
          this.bofang = 1;
        }
      }
    },
    // 获取视频播放时长
    getVidDur() {
      return GBvideo().currentTime;
    },
    // 播放速度
    bofangRate() {
      let bofangRate = document.getElementById("video");
      bofangRate.playbackRate = 2;
      // console.log(bofangRate.networkState);
    },
    // 全屏
    quanping() {
      let quanping = document.getElementById("video");
      let GBquanping = document.getElementById("GBvideo");
      if (quanping) {
        quanping.webkitRequestFullScreen();
      } else if (GBquanping) {
        GBquanping.webkitRequestFullScreen();
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 改变设备号
    changed_id(e, index) {
      console.log("改变设备号", e);
      this.queryParam.d_id = e;
      this.startMoren();
      this.getdateList();
      console.log(this.ws_bind_d_ids.length);
      for (let index1 = 0; index1 < this.ws_bind_d_ids.length; index1++) {
        // console.log("222");
        let BGCblue = document.getElementsByClassName("deviseList_name");
        if (index1 != index) {
          BGCblue[index1].style.background = "#cfe7fa";
          BGCblue[index1].style.color = "#1486e6";
        } else {
          //被点击
          BGCblue[index1].style.color = "#cfe7fa";
          BGCblue[index1].style.background = "#1486e6";
        }
      }
    },
    // 查询工地绑定设备信息
    GetWsbinddevices(ws_id) {
      const param = {
        ws_id: ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params).then((res) => {
        this.wsbinddevices = res.data;

        if (this.wsbinddevices[0].dplatform == "htx") {
          this.startMoren();
          this.getdateList();
        } else if (this.wsbinddevices[0].dplatform == "gbs") {
          // this.GetGBPlaybackQuery(this.wsbinddevices[0].dimei);
          this.GetSevenDaysGBPlaybackQuery(this.wsbinddevices[0].dimei);
          // this.GetHaveSevenDaysGBPlaybackQuery(this.wsbinddevices[0].dimei);
        }
        // console.log("res.data", this.ws_bind_d_ids);
      });
    },
    // 录像查询
    GetGBPlaybackQuery(value, time) {
      let startTime = "";
      let endTime = "";
      if (time) {
        console.log("time", this.$Format(time));
        endTime = this.$Format(time).split(" ")[0] + " 23:59:59";
        startTime = this.$Format(time).split(" ")[0];
      } else {
        endTime = new Date().toLocaleString().replaceAll("/", "-");
        // console.log("endTime", endTime);
        // let endTime = new Date().getTime() - 1000 * 3600 * 24 * 7;
        startTime = this.getLastSevenDays(0);
        // console.log("startTime", startTime);
      }

      let params = {
        gb28181: value,
        // starttime: "2024-10-10 00:00:00",
        // endtime: "2024-10-24 17:00:00",
        starttime: startTime + " 00:00:00",
        endtime: endTime,
      };
      GBPlaybackQuery(params).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.videoList = this.list;
          if (res.data.length > 0) {
            this.GBPlaybackStart(res.data[0]);
            this.GBshowVideo = true;
            res.data.forEach((el) => {
              this.dateList1.push(el.endTime.split(" ")[0]);
            });
          } else {
            this.GBshowVideo = false;
          }
        }
      });
    },
    // 查询7天内有录像的日期
    GetSevenDaysGBPlaybackQuery(value) {
      // for (let daysI = 0; daysI < 8; daysI++) {
      // console.log("daysI", daysI);
      let startTime = "";
      let endTime = "";
      // endTime = new Date().toLocaleString().replaceAll("/", "-");
      endTime = this.getLastSevenDays(0);
      startTime = this.getLastSevenDays(7);
      // endTime = this.getLastSevenDays(daysI);
      // startTime = this.getLastSevenDays(daysI);
      let params = {
        gb28181: value,
        starttime: startTime + " 00:00:00",
        endtime: endTime + " 23:59:59",
      };
      GBPlaybackQuery(params)
        .then((res) => {
          if (res.code == 200) {
            this.list = [];
            this.videoList = [];
            if (res.data.length > 0) {
              this.GBshowVideo = true;
              let length = res.data.length;
              res.data.forEach((el, index) => {
                // 获取7天内有视频的日期
                this.dateList1.push(el.endTime.split(" ")[0]);
                //获取7天内最近一天有视频的日期和此日期内所有的视频
                if (
                  res.data[length - 1].endTime.split(" ")[0] ==
                  res.data[index].endTime.split(" ")[0]
                ) {
                  this.list.push(el);
                  this.videoList.push(el);
                  // this.dateList1.push(el.endTime.split(" ")[0]);
                }
              });
            }
          }
        })
        .then(() => {
          // 播放第一段视频回放
          this.GBPlaybackStart(this.list[0]);
          let p_name_date = document.getElementsByClassName("p_name_date");
          p_name_date[0].style.color = "#1890ff";
        });
      // }
    },

    // 获取前i天的日期
    getLastSevenDays(i) {
      var date = new Date(),
        timestamp,
        newDate;
      if (!(date instanceof Date)) {
        date = new Date(date.replace(/-/g, "/"));
      }
      timestamp = date.getTime();
      newDate = new Date(timestamp - i * 24 * 3600 * 1000);
      var month = newDate.getMonth() + 1;
      month = month.toString().length == 1 ? "0" + month : month;
      var day =
        newDate.getDate().toString().length == 1
          ? "0" + newDate.getDate()
          : newDate.getDate();
      return [newDate.getFullYear(), month, day].join("-");
    },
    // 视频点播
    GBPlaybackStart(value) {
      let params = {
        gb28181: value.deviceId,
        starttime: value.startTime,
        endtime: value.endTime,
      };
      GBPlaybackStart(params).then((res) => {
        if (res.code == 200) {
          // this.showVideo = true;

          // this.GBvideoPlayTime = "00:00";
          this.GetGBvideoPlayTime();
          console.log(" this.GBvideoPlayTime", this.GBvideoPlayTime);

          this.GBVideoValue = res.data;
          this.GBVideoImei = value.deviceId;
          this.startPlayGBS(res.data.rtcs);
          this.nowplayname = value.startTime + "—" + value.endTime;
          // 获取回放时长
          let startTime = value.startTime.replace(/-/g, "/");
          let startTime1 = new Date(startTime).getTime() / 1000;
          let endTime = value.endTime.replace(/-/g, "/");
          let endTime1 = new Date(endTime).getTime() / 1000;
          this.GBvideoDuration = endTime1 - startTime1;
          // console.log(startTime1, endTime1, this.GBvideoDuration);

          // let p_name_date = document.getElementsByClassName("p_name_date");
          // p_name_date[0].style.color = "#1890ff";
        }
      });
    },
    startPlayGBS(zlmsdpUrl) {
      // console.log("startPlayGBS", zlmsdpUrl);

      this.player = new ZLMRTCClient.Endpoint({
        element: document.getElementById("GBvideo"), // video 标签
        debug: true, // 是否打印日志
        zlmsdpUrl: zlmsdpUrl, //SDP消息交换的服务器URL 流地址  rtcs
        simulcast: false, //是否启用多路复用
        useCamera: false, //使用摄像头
        audioEnable: false, //使用麦克风
        videoEnable: true, //指定是否仅接收媒体流（即作为接收端）。如果为true，则不会发送本地媒体流到远端。
        recvOnly: true, //是否仅接收媒体流
        resolution: { w: 735, h: 425 },
        usedatachannel: false,
      });
      // console.log("aaaaa", this.player);

      // this.player.start();
      // 监听事件

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
        function (e) {
          // ICE 协商出错
          console.log("ICE 协商出错", e);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
        function (e) {
          //获取到了远端流，可以播放
          console.log("播放成功", e.streams);
        }
      );
    },
    // 切换回放
    ChangeVideo(value, index) {
      console.log("ChangeVideoindex", index, value);
      this.nowplayname = value.startTime + "—" + value.endTime;
      for (let index1 = 0; index1 < this.videoList.length; index1++) {
        let p_name_date = document.getElementsByClassName("p_name_date");
        // p_name_date[index1].style.color = "#fffff";

        if (index1 != index) {
          p_name_date[index1].style.color = "#000";
          // console.log("black", index1);
        } else {
          p_name_date[index].style.color = "#1890ff";
          // console.log("blue", index);
        }
      }
      this.GBPlaybackStop(value);
    },
    // 停止点播
    GBPlaybackStop(value) {
      let params = {
        gb28181: this.GBVideoImei,
        stream: this.GBVideoValue.stream,
      };
      GBPlaybackStop(params).then((res) => {
        if (res.code == 200) {
          // var remote = document.getElementById("GBvideo");
          // remote.pause();
          // //关闭流
          // this.player.close();
          // this.player = null;
          this.player.close();
          delete this.player;
          this.player = null;
          const remote = document.getElementById("GBvideo");
          if (remote) {
            remote.srcObject = null;
            remote.load();
          }
          clearInterval(this.GetGBvideoPlayTimeSetInterval);
          this.GetGBvideoPlayTimeSetInterval = null;
        }
      });
      setTimeout(() => {
        this.M = 0;
        this.S = 0;
        if (value) {
          this.GBPlaybackStart(value);
        }
      }, 100);
    },
    // 回放暂停
    GBPlaybackPause() {
      let params = {
        stream: this.GBVideoValue.stream,
      };
      GBPlaybackPause(params).then((res) => {
        if (res.code == 200) {
          clearInterval(this.GetGBvideoPlayTimeSetInterval);
          this.GetGBvideoPlayTimeSetInterval = null;
          console.log("回放暂停", this.GBvideoPlayTime);
          // let bofang = document.getElementById("GBvideo");
          // bofang.pause();
        }
      });
    },
    // 回放恢复
    GBPlaybackResume(seektime) {
      let params = {
        stream: this.GBVideoValue.stream,
      };
      GBPlaybackResume(params).then((res) => {
        this.GBPlaybackSeek(seektime);
        this.GetGBvideoPlayTime();
      });
    },
    // 回放拖动
    GBPlaybackSeek(Changetime) {
      // console.log("回放拖动this.videoCurrentTime", this.videoCurrentTime);
      // this.S = this.S + Changetime;
      let params = {};
      if (Changetime) {
        let videoCurrentTime = 0;
        if (Changetime > 0) {
          videoCurrentTime = this.M * 60 + this.S;
        } else {
          videoCurrentTime = this.M * 60 + this.S;
        }

        params = {
          stream: this.GBVideoValue.stream,
          // seektime: videoCurrentTime * 1 + Changetime * 1,
          seektime: videoCurrentTime,
        };
      } else {
        params = {
          stream: this.GBVideoValue.stream,
          seektime: this.videoCurrentTime * 1,
          // seektime: 5.125,
        };
      }

      GBPlaybackSeek(params).then((res) => {
        if (res.code == 200) {
          // this.S = this.S + Changetime * 1 + 1;
          let bofang = document.getElementById("GBvideo");
          bofang.play();
        }
      });
    },
    // 直播当前播放时间
    GetGBvideoPlayTime() {
      // let M = 0;
      // let S = 0;
      let SS = 0;
      let MM = 0;
      let that = this;
      this.GetGBvideoPlayTimeSetInterval = setInterval(() => {
        // 如果计时==播放时长，则停止计时
        // console.log("MM * 60", MM, SS);
        // console.log("MM * 60 + SS", MM * 60 + SS * 1);
        if (MM * 60 + SS * 1 >= this.GBvideoDuration) {
          clearInterval(that.GetGBvideoPlayTimeSetInterval);
        } else {
          if (that.S < 59) {
            that.S = that.S + 1;
          } else {
            that.M = that.M + 1;
            that.S = 0;
          }
          // console.log("M+S", that.M, that.S);

          if (that.S < 10) {
            SS = "0" + that.S;
          } else {
            SS = that.S;
          }
          if (that.M < 10) {
            MM = "0" + that.M;
          } else {
            MM = that.M;
          }
        }

        that.GBvideoPlayTime = MM + ":" + SS;

        // this.GBvideoPlayTime = MM + ":" + SS;
        // console.log("this.GBvideoPlayTime", this.GBvideoPlayTime);
      }, 1000);

      // console.log("this.GBvideoPlayTime", this.GBvideoPlayTime);
    },
  },
};
</script>
<style lang="less" scoped>
// .ant-calendar-cell,
// .ant-calendar-selected-date,
// .ant-calendar-selected-day {
//   color: red !important  ;
// }
.bm-view {
  width: 100%;
  height: 300px;
}
.progress {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.prog-item {
  width: 50%;
  height: 5px;
  line-height: 5px;
  border-left: 1px solid #ccc;
}
.status0 {
  // background: rgba(24, 144, 255, 0.3);
  background: #1890ff;
}
.status1 {
  background: #1890ff;
}
.speed-btn {
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.speed-btn + .speed-btn {
  margin-left: 10px;
}
.speed-btn .icon {
  cursor: pointer;
}
.downPlay {
  // margin-top: 10px;
  margin-right: 5px;
}
.NoVideo {
  width: "100%";
  height: "300px";
  text-align: center;
  line-height: 300px;
  font-size: 20px;
  font-weight: 800;
}

// 页面宽度小于1080时
@media screen and (max-width: 1800px) {
  .calendar_rili {
    position: absolute;
    // margin-left: 38%;
    margin-left: 5%;
    background-color: #fff;
    z-index: 999;
  }
}
//页面宽度大于等于1080px时，背景变红色。
@media screen and (min-width: 1800px) {
  .calendar_rili {
    position: absolute;
    // margin-left: 38%;
    margin-left: 5%;
    background-color: #fff;
    z-index: 999;
  }
}
/deep/.ant-badge-status-error {
  background-color: gray;
  border-radius: 50%;
}
/deep/.ant-badge-status-success {
  background-color: #1890ff;
  border-radius: 50%;
}
.calendar_input {
  position: relative;
  width: 260px;
}
/deep/.a-tooltip {
  outline: none !important;
}
.playBTN {
  // margin-right: 20px;
  margin-top: 10px;
  .a_button {
    margin-right: 5px;
  }
}
.speedBTN {
  margin-left: -40px;
}
// // 播放按钮
video::-webkit-media-controls-play-button {
  display: none !important;
}
// 当前播放时间
video::-webkit-media-controls-current-time-display {
  display: none !important;
}
.videoPlayTime {
  margin-top: -35px;
  margin-left: 10px;
  font-size: 16px;
  color: white;
  z-index: 9999;
  // margin-bottom: 35px;
  // font-weight: 800;
}
// 剩余时间
// video::-webkit-media-controls-time-remaining-display {
//   display: none !important;
// }
// 音量按钮
// video::-webkit-media-controls-volume-control-container {
//   display: none !important;
// }
// 全屏
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
.haveVideo {
  display: flex;
  width: 100%;
  .haveVideo_left {
    width: 68%;
    padding-right: 5px;
  }
  .haveVideo_right {
    width: 32%;
    height: 300px;
    // background-color: pink;
    .border {
      margin-top: 5px;
      border: 1px rgba(48, 48, 48, 0.5) solid;
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
    }
    .pointer {
      cursor: pointer;
      padding-left: 5px;
    }
    .pointer :hover {
      color: #1890ff !important;
    }
    .videoListLength {
      background-color: #000000cb;
      color: #fff;
      text-align: center;
    }
  }
  .p_name_date {
    display: flex;
    // .p_name_time {
    //   //  margin-left: 5px;
    //   // margin-left: 1%;
    // }
  }
}
.siteName {
  font-weight: 800;
  margin-bottom: 5px;
}
.deviseList {
  display: flex;
  margin: 10px 0;
  div {
    margin: 0 5px;
    cursor: pointer;
    display: flex;
  }
  .deviseList_name {
    padding: 5px;
    border-radius: 5px;
    color: #1486e6;
    // background-color: #fff;
    background-color: #cfe7fa;
  }
}
</style>
