<!-- 考勤 -->
<template>
  <div>
    <div class="modal-content attend-header">
      <div>
        <a-icon type="environment" theme="twoTone" class="model-site-name" />
        <span>{{ record.ws_name }}</span>
      </div>
    </div>
    <s-search :queryItems="queryItems" @fetchData="fetchList" ref="SSearch">
    </s-search>
    <table-merge
      :loading="tableLoading"
      :attendData="attendData"
      ref="tablePage"
      @paCurrent_Size="paCurrent_Size"
    />
  </div>
</template>

<script>
import { GetAttend, GetSiteCalendar } from "@/api/site";
import { SSearch } from "@/components";

// import { ExportStopped } from "@/api/export";
import TableMerge from "./AttendTable.vue";
const queryItems = [
  {
    label: "员工姓名",
    value: "w_name",
    type: "input",
  },
  {
    label: "岗位",
    value: "w_post",
    type: "select",
    selectOption: "WorkPost",
  },
  {
    label: "人员所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "考勤日期查询",
    value: "time",
    type: "dateRange",
    range: ["time_start", "time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
const orderParam = [
  "ws_id",
  "w_name",
  "w_post",
  "bc_name",
  "time_start",
  "time_end",
  "pageNo",
  "pageSize",
];
export default {
  name: "SiteAttend",
  components: {
    TableMerge,
    SSearch,
  },
  props: {
    record: {
      type: Object,
      default: {
        ws_name: "",
      },
    },
  },
  data() {
    this.queryItems = queryItems;
    return {
      // 查询参数
      queryParam: {},
      tableLoading: false,
      attendData: {},
    };
  },
  created() {
    this.queryParam.ws_id = this.record.ws_id;
    this.siteName = this.$route.query.name;
    this.fetchAttend();
    // this.initCalendar();
    this.queryItems[3].defaultValue = [];
  },
  mounted() {},
  watch: {},
  methods: {
    paCurrent_Size(value) {
      // console.log(value);
      this.queryParam.pageNo = value.current;
      this.queryParam.pageSize = value.pageSize;
      this.fetchAttend();
    },
    fetchAttend(parameter) {
      this.tableLoading = true;
      const param = Object.assign({}, parameter, this.queryParam);

      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      GetAttend(params).then((res) => {
        // 处理数据
        this.tableLoading = false;
        this.attendData = res.data;
      });
    },
    //查询触发
    fetchList(value) {
      this.queryParam = value;
      // console.log(value);
      this.queryParam.ws_id = this.record.ws_id;
      this.fetchAttend();
      // this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.attend-header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
/deep/ .ant-col-6 {
  width: 45%;
  // width: 33%;
}
/deep/ .SiteNeat_date {
  // width: 300px;
}
/deep/ .BTNFlex {
  // margin-left: 50px;
  // margin-top: 15px;
}
</style>
