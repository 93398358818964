import request from "@/utils/request";

const api = {
  getPatrolphoto: "/dsmcore/api/screen/patrolphoto", // 巡查看板快照
  getSiteInfo: "/dsmcore/api/screen/patrolvideo", // 巡查看板，工地信息查询
}
export function GetPatrolphoto(parameter) {
  return request({
    url: api.getPatrolphoto,
    method: "post",
    params: parameter,
  });
}
export function GetSiteInfo(parameter) {
  return request({
    url: api.getSiteInfo,
    method: "post",
    params: parameter,
  });
}