<!-- 快照 -->
<template>
  <div>
    <div class="modal-content top">
      <div class="riqi">
        <span>日期：</span>
        <a-range-picker
          :default-value="[
            moment(defaultValueStart, dateFormat),
            moment(moment().endOf('day'), dateFormat),
          ]"
          :format="dateFormat"
          :value-format="dateFormatValue"
          :ranges="{
            今天: [moment().startOf('day'), moment().endOf('day')],
            当月: [moment().startOf('month'), moment().endOf('month')],
          }"
          @change="onChangeDate"
        >
        </a-range-picker>
      </div>
      <div class="dizhi">
        <a-icon type="environment" theme="twoTone" class="model-site-name" />
        <span>{{ record.ws_name }}</span>
      </div>
    </div>

    <div class="modal-content">
      <div class="searchKZ">
        <div>
          <a-checkbox-group
            v-model="value"
            name="checkboxgroup"
            :options="plainOptionsKZ"
            @change="onChangeKZ"
          />
        </div>
      </div>
      <div class="DCBTN">
        <a-button type="plain" class="a-button">
          <a-checkbox @change="onCheckAllInstitute"> 全选 </a-checkbox>
        </a-button>
        <a-button
          @click="handleExport"
          :disabled="checkedList.length === 0"
          type="plain"
          icon="cloud-download"
          class="a-button"
          >导出</a-button
        >
      </div>
      <div v-for="(item, i) in datakey" :key="i" style="margin-top: 3vh">
        <div style="margin-bottom: 15px">
          <div class="dataClass">{{ item }}</div>
          <a-button type="plain">
            <a-checkbox
              class="check-all"
              :indeterminate="indeterminate"
              :checked="checkAll.includes(i)"
              @change="onCheckAllChange($event, item, i)"
              >全选</a-checkbox
            >
          </a-button>
        </div>

        <a-checkbox-group
          v-model="checkedList[i]"
          @change="onChangeCheck(item, i)"
          v-show="plainOptions.length > 0"
          style="width: 100%"
        >
          <a-row>
            <a-col
              :span="4"
              v-for="(itemindex, index) in dataList[item]"
              :key="index"
            >
              <a-checkbox :value="itemindex + i">
                <!-- <a-icon type="plus-circle" class="enlarge-img" /> -->
                <!--快照 -->
                <!-- <img
                  :src="`${pathUrl}` + itemindex"
                  :alt="itemindex"
                  class="check-img"
                /> -->
              </a-checkbox>
              <img
                v-lazy="`${pathUrl}` + itemindex.split(',')[0]"
                alt="img"
                class="check-img"
                @click.prevent="enlarge(itemindex, item, index)"
              />
            </a-col>
          </a-row>
        </a-checkbox-group>
        <!-- <a-empty v-show="plainOptions.length === 0" /> -->
      </div>
      <a-empty v-if="datakey1.length === 0" />
    </div>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出选中的照片？</p>
    </a-modal>
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleEnlarge"
      @cancel="enlargeCancel"
    >
      <div class="modelTitle">
        <span class="colorRed"> {{ enlargeImgDate }}</span>
      </div>
      <div class="modelIcon">
        <a-icon @click="changeImg(-1)" type="left" />
        <a-icon @click="changeImg(+1)" type="right" />
      </div>
      <img :src="enlargeImg" id="img" width="1045px" />
    </a-modal>
  </div>
</template>

<script>
import { GetSnapshot } from "@/api/site";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";
import moment from "moment";
const plainOptionsKZ = [
  "小时快照",
  // "人脸",
  // "无人快照",
  // "抽烟报警",
  // "未穿工服",
  // "穿工服",
  // "注册员工",
  "预置位快照",
  "人形快照",
];
export default {
  name: "SiteSnapshot",
  props: {
    record: {
      type: Object,
      default: {
        ws_name: "",
      },
    },
  },
  data() {
    return {
      dateList: [],
      dateList1: [],
      dataTime: "",
      shijianchuo: "",
      queryParam: {
        ws_id: "",
        ws_time_start: "",
        ws_time_end: "",
      },
      i: 0,
      j: 0,
      indeterminate: false,
      indeterminate1: true,
      checkAll: [],
      checkAll1: false,
      isshowCalendar: false,
      checkedList: [], // 选中的选项
      plainOptions: [], // 所有的选项

      // 导出
      visibleExport: false,
      confirmExportLoading: false,
      // 图片放大
      visibleEnlarge: false,
      enlargeImg: "",
      enlargeImgDate: "",
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      dateFormat: "YYYY/MM/DD",
      monthFormat: "YYYY/MM",
      dateFormatList: ["DD/MM/YYYY", "DD/MM/YY"],
      defaultValueStart: "2023/02/22",
      dateFormatValue: "X",
      dataList: "",
      resdataList: "",
      datakey: [],
      datakey1: [],
      massageUrl: [],
      massageUrl_download: [],
      canvasBase64Img: "",
      canvasBlobImg: null,
      plainOptionsKZ,
      value: [],
      imgDate: "",
      imgIndex: "",
    };
  },
  created() {
    // 获取当天 0 点的时间戳
    var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
    // 一天是86400秒   故 7 天前的时间戳为
    var SevenDayAgo = timeStamp - 86400 * 7;
    this.queryParam.ws_id = this.record.ws_id;

    this.defaultValueStart = this.getLastSevenDays();
    let date = [
      (this.queryParam.ws_time_start = SevenDayAgo),
      (this.queryParam.ws_time_end = Date.parse(new Date()) / 1000),
    ];
    this.onChangeDate(date);
  },
  methods: {
    moment,

    p(s) {
      return s < 10 ? "0" + s : s;
    },
    onChangeDate(date, dateString) {
      // console.log(date, dateString);
      this.queryParam.ws_time_start = date[0];
      this.queryParam.ws_time_end = date[1];
      this.fetchSnapshot();
      this.isshowCalendar = false;
    },

    fetchSnapshot() {
      let order = ["ws_id", "ws_time_start", "ws_time_end", "snapshotTypes"];
      const params = {
        ...this.queryParam,
        verify: this.$verify(this.queryParam, order),
      };
      // console.log(params);
      GetSnapshot(params).then((res) => {
        // console.log(res.data);
        // 处理数据
        const url = this.pathUrl;
        let data = res.data;
        this.resdataList = res;
        let list = [];
        // console.log(res.data);
        let dataKeyList = [];
        const paramsKey = Object.keys(data).sort().reverse();
        this.datakey1 = paramsKey;
        this.datakey = [...paramsKey];
        paramsKey.forEach((key) => {
          this.dataList = res.data;
          this.checkedList;
          const arrList = [];
          Object.keys(this.dataList).forEach((v) => {
            arrList.push([]);
          });
          this.checkedList = arrList;
          dataKeyList.push(data[key]);
        });
        this.plainOptions = dataKeyList;
      });
    },
    // 全部选中
    onCheckAllInstitute(e) {
      const bool = e.target.checked;
      if (bool) {
        // 全选
        // console.log(this.checkedList);
        this.checkAll = [];
        Object.values(this.dataList).forEach((v, i) => {
          this.checkAll.push(i);
        });
        const arr = Object.keys(this.dataList).sort().reverse();
        arr.forEach((v, i) => {
          this.checkedList[i].push(...this.dataList[v].map((n) => n + i));
        });
        // console.log(this.checkedList);
      } else {
        // 反选
        this.checkedList.forEach((v) => {
          v.length = 0;
        });
        this.checkAll = [];
        // this.checkedList = [];
      }
      let massageUrl0 = Object.values(this.dataList).sort().reverse();
      let mamassageUrl1 = [];
      massageUrl0.forEach((item) => {
        // console.log(item);
        item.forEach((i) => {
          i = i + "g";
          // console.log(i);
          if (bool) {
            mamassageUrl1.push(i);
          } else {
            mamassageUrl1 = [];
          }
        });
      });
      this.massageUrl = mamassageUrl1;
      // console.log(mamassageUrl1);
    },

    // 全选按钮
    onCheckAllChange(e, item, index) {
      if (this.checkAll.includes(index)) {
        this.checkAll = this.checkAll.filter((v) => v != index);
        this.checkedList[index] = this.checkedList[index].filter(
          (v) => v.charAt(v.length - 1) != index
        );
      } else {
        this.checkAll.push(index);
        this.checkedList[index].push(
          ...this.dataList[item].map((v) => v + index)
        );
      }
      // console.log(this.checkedList, "filtert"); //全选数据
      let arrAy1 = this.checkedList;
      let arrAy2 = arrAy1.reduce(function (a, b) {
        return a.concat(b);
      });
      // arrAy2.map((item, index) => {
      //   let massage = item.slice(0, item.length - 1);
      //   // console.log(massage); //拿到的每个图片的真实路径
      // });
      console.log(arrAy2);
      this.massageUrl = arrAy2;
      this.indeterminate = false;
    },
    getInclude2(arr1, arr2) {
      return arr1.filter((item) => {
        return arr2.includes(item);
      });
    },
    // 单选
    onChangeCheck(item, i) {
      const index = this.getInclude2(this.checkedList[i], [
        ...this.dataList[item].map((v) => v + i),
      ]);

      if (
        index.length == this.dataList[item].length &&
        !this.checkAll.includes(index)
      ) {
        this.checkAll.push(i);
      } else {
        this.checkAll = this.checkAll.filter((v) => v != i);
      }
      // console.log(this.checkedList);

      let arrAy3 = this.checkedList;
      let arrAy4 = arrAy3.reduce(function (a, b) {
        return a.concat(b);
      });
      console.log(item, i);
      // arrAy4.map((item, index) => {
      //   let msg = item.slice(0, item.length - 1);
      //   // console.log(msg); //拿到的每个图片的真实路径
      // });
      // this.massageUrl.push(msg);
      this.massageUrl = arrAy4;
      // console.log(this.massageUrl);
    },
    // 鼠标点击放大
    enlarge(item, date, index) {
      this.visibleEnlarge = true;
      this.enlargeImg = this.pathUrl + item.split(",")[0];
      // enlargeImgDate
      let time = item.split("_")[1].split(".")[0];
      console.log("item", item);
      if (item.split(",")[1] == 3) {
        this.enlargeImgDate =
          "拍照时间：" + this.$Format(Math.ceil(time / 1000));
      } else {
        this.enlargeImgDate = "";
      }
      // console.log(this.$Format(Math.ceil(time / 1000)));

      this.$nextTick(() => {
        this.changeSize();
      });
      this.imgDate = date;
      this.imgIndex = index;
    },
    changeImg(num) {
      // console.log(num, this.dataList);
      this.imgIndex = this.imgIndex + num;
      if (this.imgIndex < 0) {
        this.imgIndex = 0;
        this.$message.warning("已是当天第一张快照");
      } else if (this.imgIndex >= this.dataList[this.imgDate].length) {
        this.imgIndex = this.dataList[this.imgDate].length - 1;
        this.$message.warning("已是当天最后一张快照");
      }
      this.enlargeImg =
        this.pathUrl + this.dataList[this.imgDate][this.imgIndex].split(",")[0];

      let time0 = this.dataList[this.imgDate][this.imgIndex];
      if (time0.split(",")[1] == 3) {
        let time = time0.split("_")[1].split(".")[0];
        this.enlargeImgDate =
          "拍照时间：" + this.$Format(Math.ceil(time / 1000));
      } else {
        this.enlargeImgDate = "";
      }
    },
    enlargeCancel() {
      this.visibleEnlarge = false;
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      let check = [];
      this.downloadFiles(check);
      this.visibleExport = false;
    },
    exportCancel() {
      this.visibleExport = false;
    },
    // 前端下载
    downloadFiles(content) {
      let massageUrl1 = this.unique(this.massageUrl);
      let array = [];
      massageUrl1.map((item, index) => {
        let msg = item.slice(0, item.length - 1);
        // console.log(msg); //拿到的每个图片的真实路径
        array.push(msg);
      });

      content = array;
      content.forEach((el) => {
        let imgsrc =el.split(",")[0];
        let name =  el.split(",")[0];
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          let url = canvas.toDataURL("image/png");
          let a = document.createElement("a");
          let event = new MouseEvent("click");
          a.download = name || "photo";
          a.href = url;
          a.dispatchEvent(event);
          // console.log(image, 0, 0, image.width, image.height);
        };

        // image.src = imgsrc;
        image.src = this.pathUrl + imgsrc;
        console.log(image.src);
      });
    },

    // 缩放
    changeSize() {
      const img = document.querySelector("#img");
      const useZoom = (callback = () => {}) => {
        let scale = 1;
        const zoom = (e) => {
          const { offsetX, offsetY } = e;
          let _scale = scale;
          _scale += e.deltaY / 1000;
          if (_scale < 0.3) return;
          scale = _scale;
          callback({ x: offsetX, y: offsetY }, scale);
        };

        return {
          zoom,
        };
      };

      const update = (position, scale) => {
        img.style.transformOrigin = `${position.x}px ${position.y}px`;
        img.style.transform = `scale(${scale})`;
      };

      const { zoom } = useZoom(update);
      img.onwheel = zoom;
    },

    // 获取前七天的日期
    getLastSevenDays() {
      var date = new Date(),
        timestamp,
        newDate;
      if (!(date instanceof Date)) {
        date = new Date(date.replace(/-/g, "/"));
      }
      timestamp = date.getTime();
      newDate = new Date(timestamp - 7 * 24 * 3600 * 1000);
      var month = newDate.getMonth() + 1;
      month = month.toString().length == 1 ? "0" + month : month;
      var day =
        newDate.getDate().toString().length == 1
          ? "0" + newDate.getDate()
          : newDate.getDate();
      return [newDate.getFullYear(), month, day].join("-");
    },
    // 数组去重
    unique(arr) {
      let newArr = [arr[0]];
      for (let i = 1; i < arr.length; i++) {
        let repeat = false;
        for (let j = 0; j < newArr.length; j++) {
          if (arr[i] === newArr[j]) {
            repeat = true;
            break;
          } else {
          }
        }
        if (!repeat) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 快照类型多选
    onChangeKZ(checkedValues) {
      this.queryParam.snapshotTypes = this.value.toString();
      this.fetchSnapshot();
    },
  },
};
</script>
<style scoped lang="less">
.ant-col {
  margin-bottom: 16px;
  padding-right: 10px;
}
.ant-checkbox {
  width: calc(100% - 16px);
  height: 100%;
  position: absolute;
  left: 0;
}
.check-img {
  width: 100%;
  height: 80px;
  vertical-align: top;
  cursor: pointer;
}
.check-all .ant-checkbox {
  position: absolute;
  left: 0;
  top: 7px;
}
.enlarge-img:hover {
  color: #1890ff;
}

/deep/.ant-badge-status-error {
  background-color: gray;
  border-radius: 50%;
}
/deep/.ant-badge-status-success {
  background-color: #1890ff;
  border-radius: 50%;
}
.top {
  display: flex;
}

.riqi {
  // width: 405px;
  width: 400px;
}
.dizhi {
  width: 43%;
  // margin-top: 20px;
  margin-left: 10px;
}
.dataClass {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}
.DCBTN {
  // text-align: right;
  .a-button {
    margin-right: 10px;
  }
}
.searchKZ {
  display: flex;
  // margin-left: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  /deep/ .ant-checkbox-wrapper {
    font-size: 14px;
  }
  .serarchKZBTN {
    // margin-top: 10px;
    text-align: right;
  }
}
/deep/.ant-checkbox-group-item {
  margin-right: 0;
}
.modelIcon {
  position: absolute;
  width: 97%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  font-size: 50px;
  font-weight: 800;
  color: black;
}
.modelIcon :hover {
  color: #1890ff;
}
.colorRed {
  color: red;
}
.modelTitle {
  text-align: center;
  font-size: 16px;
}
</style>
