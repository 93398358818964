<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchData"
      @infoWindowClose="infoWindowClose"
    ></s-search>
    <div class="explain_map_flex">
      <div class="explain_map">
        <p>只展示施工中绑定过摄像头的工地，以设备最后上传的经纬度为准</p>
      </div>
      <div class="explain_map">
        <a-tooltip>
          <template slot="title">全屏显示</template>
          <a-button type="primary" @click="MapZoom()" class="map_quanpingBTN">
            <!-- <a-icon type="fullscreen"/> -->
            <img
              src="../../assets/images/site_img/fullscreen_img.png"
              alt=""
              class="fullscreen_img"
            />
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <baidu-map
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @ready="handler"
      class="bm-view"
      :style="{ height: mapHeight }"
      id="screen"
      mapType="BMAP_SATELLITE_MAP"
    >
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <bm-control>
        <div class="status-con" id="status-con">
          <!-- 只展示施工中-绑定过摄像头的工地 -->
          <div class="status-item" :class="{ status1: device_status === '1' }">
            <!-- @click="changeStatus('1')" -->
            <!-- <a-icon type="environment" class="status-icon" /> -->
            <img src="../../assets/images/map_success.png" alt="" />
            <!-- <span>施工中</span> -->
            <span>在线</span>
          </div>
          <div class="status-item" :class="{ status3: device_status === '2' }">
            <!-- @click="changeStatus('2')" -->
            <!-- <a-icon type="environment" class="status-icon" /> -->
            <img src="../../assets/images/map_error.png" alt="" />
            <!-- <span>无人施工</span> -->
            <span>离线</span>
          </div>
          <!-- :class="{ status2: camera === '2' }" -->
          <div class="status-item">
            <!-- @click="changeStatus('')" -->
            <!-- <a-icon type="environment" class="status-icon" /> -->
            <img src="../../assets/images/map_warn.png" alt="" />
            <!-- <span>暂停施工</span> -->
            <span>无摄像头</span>
          </div>

          <!-- <div class="status-item" @click="changeStatus('0')">
            <a-icon type="appstore" class="status-icon" />
            <span>全部状态</span>
          </div> -->
        </div>
      </bm-control>
      <bm-control class="exit_control">
        <div id="exit_icon" class="exit_icon" ref="exit_icon">
          <a-tooltip placement="bottom" :getPopupContainer="getPopupContainer">
            <template slot="title">退出全屏</template>
            <a-button type="primary" @click="MapZoom_Exit()">
              <img
                src="../../assets/images/site_img/fullscreen_exit_img.png"
                alt=""
                class="fullscreen_exit_img"
              />
            </a-button>
          </a-tooltip>
        </div>
      </bm-control>
      <!-- 在线状态mark -->
      <!-- <bml-marker-clusterer :styles="style1"> -->
      <bm-marker
        class="Marker"
        v-for="p in points1"
        :key="p.ws_id"
        :position="p"
        :dragging="false"
        :top="false"
        :zIndex="99"
        :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
        @click="clickMarker(p)"
      ></bm-marker>
      <!-- </bml-marker-clusterer> -->
      <!-- 离线的marker点 -->
      <!-- <bml-marker-clusterer :styles="style2"> -->
      <bm-marker
        class="Marker"
        v-for="p in points2"
        :key="p.ws_id"
        :position="p"
        :dragging="false"
        :top="false"
        :zIndex="99"
        :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
        @click="clickMarker(p)"
      ></bm-marker>
      <!-- </bml-marker-clusterer> -->
      <!-- 无摄像头的marker点 -->
      <!-- <bml-marker-clusterer :styles="style3"> -->
      <bm-marker
        class="Marker"
        v-for="p in points3"
        :key="p.ws_id"
        :position="p"
        :dragging="false"
        :top="false"
        :zIndex="99"
        :icon="{ url: p.icon, size: { width: 30, height: 30 } }"
        @click="clickMarker(p)"
      ></bm-marker>
      <!-- </bml-marker-clusterer> -->
      <div>
        <bm-info-window
          class="info"
          :position="windowPosition"
          :show="show"
          :autoPan="true"
          @close="infoWindowClose"
          @open="infoWindowOpen"
          ref="InfoWindow"
        >
          <div>
            <!-- 需要添加判断显示  摄像头 在线   离线 -->
            <span>施工中</span>
            <span>
              <span
                v-if="windowPosition.device_status == '1'"
                style="color: green"
              >
                <!-- <img src="../../assets/images/map_success.png" alt="" /> -->
                在线
              </span>
              <span
                v-if="
                  windowPosition.device_status == '2' &&
                  windowPosition.camera != '2'
                "
                style="color: red"
              >
                <!-- <img src="../../assets/images/map_error.png" alt="" /> -->
                离线
              </span>
            </span>
            <span
              v-if="
                windowPosition.camera == '2' &&
                windowPosition.device_status == '2'
              "
              style="color: blue"
            >
              <!-- <img src="../../assets/images/map_warn.png" alt="" /> -->
              无摄像头</span
            >
          </div>
          <!-- 去掉了“直辖市-”-->
          <a @click="jumpToSiteList()" title="点击查看工地信息">{{
            windowPosition.ws_name
          }}</a>
          <div>
            <span>工程管家：</span><span>{{ windowPosition.w_name_gj }}</span
            ><span>{{ windowPosition.gj_phone }}</span>
          </div>
          <div>
            <span>项目经理：</span>{{ windowPosition.w_name_jl
            }}<span>{{ windowPosition.jl_phone }}</span>
          </div>
          <div class="TClist">
            <span
              class="zhibo"
              v-show="windowPosition.device_status == '1' ? true : false"
            >
              <a @click="JumpSubpage(record, 'SitePreview', '直播')"
                >直播</a
              ></span
            >
            <span class="kuaizhao">
              <a @click="JumpSubpage(record, 'SiteSnapshot', '快照')"
                >快照</a
              ></span
            >
            <span class="huifang">
              <a @click="JumpSubpage(record, 'SitePlayback', '回放')"
                >回放</a
              ></span
            >
            <span class="kaoqin">
              <a @click="JumpSubpage(record, 'SiteAttend', '考勤')"
                >考勤</a
              ></span
            >
            <!-- <span>
              <a @click="JumpSubpage(record, 'SiteDetail', '详情')">工地详情</a>
            </span> -->

            <span class="xiangqing">
              <a @click="JumpSubpage(record, 'SiteParticulars', '详情')"
                >工地详情</a
              >
            </span>
          </div>
        </bm-info-window>
      </div>
    </baidu-map>
    <!-- <detail ref="detail_methods" v-show="false" /> -->
    <!-- 展开弹出框 -->
    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <SitePreview
        :record="record"
        :SiteList_previer_true="SiteList_previer_true"
        @JumpSubpage="JumpSubpage"
        @onClose="onClosePreview"
        v-if="SitePreview"
        ref="SitePreview"
      ></SitePreview>
      <SiteSnapshot :record="record" v-if="SiteSnapshot"></SiteSnapshot>
      <SitePlayback
        :record="record"
        v-if="SitePlayback"
        ref="SitePlayback"
      ></SitePlayback>
      <SiteAttend :record="record" v-if="SiteAttend"></SiteAttend>
      <!-- <SiteDetail :record="record" v-if="SiteDetail"></SiteDetail> -->
      <SiteParticulars
        :record="record"
        v-if="SiteParticulars"
      ></SiteParticulars>
    </a-drawer>
  </div>
</template>

<script>
import { SSearch } from "@/components";
import { GetSiteMap } from "@/api/site";
import router from "../../router";
import SitePreview from "@/views/site/SitePreview.vue"; //直播
import SiteSnapshot from "@/views/site/SiteSnapshot.vue"; //快照
import SitePlayback from "@/views/site/SitePlayback.vue"; //回放
import SiteAttend from "@/views/site/SiteAttend.vue"; //考勤
import SiteDetail from "@/views/site/SiteDetail.vue"; //详情
import SiteParticulars from "@/views/site/SiteParticulars.vue";
import Detail from "../inspection/detail.vue";
import { BmlMarkerClusterer } from "vue-baidu-map";
import { number } from "echarts";
import screenfull from "screenfull";

// import detail from "../inspection/detail.vue";
// 搜索条件
const queryItems = [
  { label: "项目经理", value: "w_name_jl", type: "input" },
  {
    label: "分公司名称",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
  },
  {
    label: "工地名称",
    value: "ws_name",
    type: "input",
    scopedSlots: { customRender: "ws_name" },
  }, //新增

  { label: "工程管家", value: "w_name_gj", type: "input" },
  {
    label: "是否有摄像头",
    value: "camera",
    type: "select",
    selectOption: "Camera",
  }, //新增
  {
    label: "设备状态",
    value: "device_status",
    type: "select",
    selectOption: "SiteDeviceStatus",
  }, //新增

  {
    label: "无人施工天数",
    value: "no_work",
    type: "select",
    selectOption: "NoWorks",
  }, //新增
  // { label: "地区", value: "ws_area", type: "area" },
];
export default {
  name: "CustomMap",
  components: {
    SSearch,
    GetSiteMap,
    SitePreview,
    SiteSnapshot,
    SitePlayback,
    SiteAttend,
    SiteDetail,
    SiteParticulars,
    BmlMarkerClusterer,
    Detail,
  },
  data() {
    this.queryItems = queryItems;
    const markers = [];
    return {
      ws_id: "",
      mapHeight: "450px",
      //   ws_status: "0",
      queryParam: {},
      bcName: "",
      // center: { lng: 0, lat: 0 },
      center: { lng: 116.407394, lat: 39.904211 },
      zoom: 3,
      points1: [],
      points2: [],
      points3: [],
      show: false,
      windowPosition: {},
      zai_li_xian: "",
      wu_camera: "",
      device_status: "",
      Camera: "1",
      // 弹出框
      title: "",
      visible: false,
      record: {
        ws_id: "",
        ws_name: "",
      },
      jsongetlocaMapData: "",
      MapData: "",
      SitePreview: false,
      SiteSnapshot: false,
      SitePlayback: false,
      SiteDetail: false,
      SiteAttend: false,
      SiteEdit: false,
      SiteParticulars: false,
      markers,
      style1: [
        {
          url: require("../../assets/images/map_success.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
      style2: [
        {
          url: require("../../assets/images/map_error.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
      style3: [
        {
          url: require("../../assets/images/map_warn.png"),
          size: {
            width: 24,
            height: 27,
          },
          textColor: "#ccccC",
          opt_textSize: 10,
        },
      ],
      // maxZoomNum: 13,
      maxZoomNum: 12,
      isFull: "", //全屏检查
      setNoneTimer: null,
      buttonWidth: 100,
      SiteList_previer_true: true,
    };
  },
  created() {
    this.ws_id = this.$route.query.ws_id;
    this.fetchData();
    this.$nextTick(() => {
      this.setHeight();
    });
    window.onresize = () => {
      //调用判断全屏的方法，用来监听
      this.checkFull();
    };
    // setTimeout(() => {
    //   this.getBaiduMapPoint();
    // }, 10);
  },
  watch: {
    // 监听分公司查询条件的变化，改变地图中心点的位置
    bcName(newValue, old) {
      // console.log(newValue);
      if (newValue == "" || newValue == 0) {
        this.center = "北京市";
      } else if (newValue == 20 || newValue == 8 || newValue == 5) {
        this.center = "天津市";
      } else if (
        newValue == 6 ||
        newValue == 24 ||
        newValue == 3 ||
        newValue == 23 ||
        newValue == 4 ||
        newValue == 25
      ) {
        this.center = "北京市";
      } else if (newValue == 9) {
        this.center = "成都市";
      } else if (newValue == 17) {
        this.center = "大连市";
      } else if (newValue == 15) {
        this.center = "哈尔滨市";
      } else if (newValue == 14) {
        this.center = "济南市";
      } else if (newValue == 13) {
        this.center = "南京市";
      } else if (newValue == 22 || newValue == 10) {
        this.center = "青岛市";
      } else if (newValue == 7) {
        this.center = "石家庄市";
      } else if (newValue == 12) {
        this.center = "武汉市";
      } else if (newValue == 16 || newValue == 19 || newValue == 21) {
        this.center = "西安市";
      } else if (newValue == 18) {
        this.center = "郑州市";
      } else if (newValue == 11) {
        this.center = "重庆市";
      }
    },
    isFull(newValue, oldValue) {
      event.keyCode = 0;
      // console.log("监听是否退出全屏");
      if (this.isFull == false) {
        let status_con = document.getElementById("status-con");
        status_con.style.display = "block";
        let exit_icon = document.getElementById("exit_icon");
        exit_icon.style.display = "none";
      }
      if (this.isFull == true) {
        let exit_icon = document.getElementById("exit_icon");
        exit_icon.style.display = "block";
        console.log("111");
      }
    },
  },
  methods: {
    // 根据地址获取经纬度
    // getBaiduMapPoint() {
    //   console.log({ BMap });
    //   console.log(BMap);
    //   let myGeo = new BMap.Geocoder();
    //   console.log(myGeo);
    //   let searchJingwei =
    //     "河北省廊坊市广阳区鸿坤理想城鸿坤理想城24号楼1单元1层1室";
    //   myGeo.getPoint(searchJingwei, (point) => {
    //     if (point) {
    //       // that.map.centerAndZoom(point, 15);
    //       let latitude = point.lat;
    //       let longitude = point.lng;
    //       // that.infoWindowShow = true;
    //       console.log(latitude, longitude);
    //     }
    //   });
    // },
    fetchData(data) {
      this.queryParam = data
        ? data
        : {
            // ws_area: "",
            bc_name: "",
            // w_name: "",
            ws_name: "",
            w_name_gj: "",
            camera: "", //有无摄像头
            // d_status: "",
            no_work: "",
            device_status: "",
            w_name_jl: "",
            ws_id: "",
          };
      this.fetchMap();
    },
    setHeight() {
      let screen = document.body.clientHeight;
      // this.mapHeight = screen - 128 + "px";
      this.mapHeight = screen - 155 + "px";
    },
    handler({ BMap, map }) {
      this.center = "北京市";
      this.zoom = 12;
      // this.zoom = 13;
    },
    // changeStatus(status) {
    //   this.ws_status = status;
    //   this.device_status = status;
    //   this.fetchMap();
    // },

    async fetchMap() {
      // this.center = this.queryParam.ws_area; // 设置当前地图区域
      let order = [
        "bc_name",
        "ws_name",
        "w_name_jl",
        "w_name_gj",
        "camera",
        "device_status",
        "no_work",
      ];
      let params = {
        ...this.queryParam,
        ws_status: "",
        d_status: "",
        ws_bind_status: "",
        ws_id: this.queryParam.ws_id,
        bc_name: this.queryParam.bc_name,
        ws_name: this.queryParam.ws_name,
        w_name_jl: this.queryParam.w_name_jl, //工地项目经理
        w_name_gj: this.queryParam.w_name_gj, //工地管家
        camera: this.queryParam.camera, //有无摄像头
        device_status: this.queryParam.device_status, //摄像头状态
        no_work: this.queryParam.no_work,
        gj_phone: this.queryParam.gj_phone,
        jl_phone: this.queryParam.jl_phone,
        verify: this.$verify(
          {
            ...this.queryParam,
            ws_id: this.queryParam.ws_id,
            bc_name: this.queryParam.bc_name,
            ws_name: this.queryParam.ws_name,
            w_name_jl: this.queryParam.w_name_jl, //工地项目经理
            w_name_gj: this.queryParam.w_name_gj, //工地管家
            camera: this.queryParam.camera, //有无摄像头
            device_status: this.queryParam.device_status, //摄像头状态
            no_work: this.queryParam.no_work,
            gj_phone: this.queryParam.gj_phone,
            jl_phone: this.queryParam.jl_phone,
          },
          order
        ),
      };
      this.bcName = params["bc_name"] ? params["bc_name"] : "";
      const points1 = [];
      const points2 = [];
      const points3 = [];
      await GetSiteMap(params).then((res) => {
        if (res && res.data) {
          res.data.forEach((el) => {
            let icon = "";
            if (el.device_status == "1") {
              icon = require("../../assets/images/map_success.png");
            } else if (el.device_status == "2" && el.camera != "2") {
              icon = require("../../assets/images/map_error.png");
            } else if (el.camera == "2" && el.device_status == "2") {
              icon = require("../../assets/images/map_warn.png");
            }
            if (el.ws_long && el.ws_lat && el.device_status == "1") {
              points1.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
            if (
              el.ws_long &&
              el.ws_lat &&
              el.device_status == "2" &&
              el.camera != "2"
            ) {
              points2.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
            if (
              el.ws_long &&
              el.ws_lat &&
              el.camera == "2" &&
              el.device_status == "2"
            ) {
              points3.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                ws_name: el.ws_name.replace(/直辖市|-北京|-天津/g, ""), //工地名称
                w_name_gj: el.gj_name, //工地管家
                w_name_jl: el.jl_name, //工地项目经理
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                gj_phone: el.gj_phone,
                jl_phone: el.jl_phone,
                show: false,
                icon: icon,
              });
            }
          });
        } else {
          this.$message.error("地图返回数据不正确");
        }
        // console.log(res.data);
      });
      // this.points = points;

      setTimeout(() => {
        this.points1 = points1.slice(0, 200);
        this.points2 = points2.slice(0, 200);
        this.points3 = points3.slice(0, 200);
        // console.log("第一个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 500);
        this.points2 = points2.slice(0, 500);
        this.points3 = points3.slice(0, 500);
        // console.log("第二个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 1000);
        this.points2 = points2.slice(0, 1000);
        this.points3 = points3.slice(0, 1000);
        // console.log("第三个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 1500);
        this.points2 = points2.slice(0, 1500);
        this.points3 = points3.slice(0, 1500);
        // console.log("第四个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1.slice(0, 2000);
        this.points2 = points2.slice(0, 2000);
        this.points3 = points3.slice(0, 2000);
        // console.log("第五个定时器数组长度" + this.points.length);
      });
      setTimeout(() => {
        this.points1 = points1;
        this.points2 = points2;
        this.points3 = points3;
        // console.log("第六个定时器数组长度" + this.points.length);
      });
    },
    clickMarker(p) {
      this.show = true;
      this.windowPosition = p;
      // console.log(this.windowPosition);
      // this.center = "北京市";
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    jumpToSiteList() {
      console.log("jumpToSiteList");
      this.$router.push({
        path: "SiteList",
        query: {
          ws_name: this.windowPosition.ws_name,
        },
      });
    },

    JumpSubpage(record, showChild, title) {
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地" + title;
      // this.record.ws_id = this.windowPosition.ws_id;
      this.record = this.windowPosition;
      // console.log(this.record);
      // console.log(this.record.ws_id);
      this[showChild] = true;
    },

    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SiteSnapshot = false;
      this.SiteDetail = false;
      this.SiteParticulars = false;
      this.SiteAttend = false;
      this.SiteEdit = false;
      if (this.SitePreview) {
        this.$refs.SitePreview.close();
        this.SitePreview = false;
      }
      if (this.SitePlayback) {
        this.$refs.SitePlayback.close();
        this.SitePlayback = false;
      }
    },
    // 关闭直播   供直播组件调用关闭直播
    onClosePreview() {
      this.SitePreview = false;
    },
    // 放大地图，隐藏其余
    MapZoom() {
      let element = document.getElementById("screen"); //指定全屏区域元素
      let status_con = document.getElementById("status-con");
      let exit_icon = document.getElementById("exit_icon");
      screenfull.toggle(element); //全屏显示
      status_con.style.display = "none";
      exit_icon.style.display = "block";
      console.log("全屏");
    },
    checkFull() {
      // let element = document.getElementById("screen"); //指定全屏区域元素
      let isFull = window.fullScreen || document.webkitIsFullScreen;
      this.isFull = isFull;
      // let exit_icon = document.getElementById("exit_icon");
      // console.log("isfull" + this.isFull);
      // if (isFull == false) {
      //   exit_icon.style.display = "none";
      //   // let noneTimer = null;
      //   // element.onmousemove = () => {
      //   //   if (noneTimer != null) {
      //   //     return;
      //   //   }
      //   //   noneTimer = setTimeout(() => {
      //   //     exit_icon.style.display = "none";
      //   //     noneTimer = null;
      //   //     console.log("isFull == false--none");
      //   //   }, 60000);
      //   // };
      // } else if (isFull == true) {
      //   var onmousemoveblockTimer = null;
      //   element.onmousemove = () => {
      //     if (onmousemoveblockTimer != null) {
      //       return;
      //     }
      //     onmousemoveblockTimer = setTimeout(() => {
      //       exit_icon.style.display = "block";
      //       console.log("onmousemove");
      //       onmousemoveblockTimer = null;
      //     }, 1000);
      //     element.onmouseout = () => {
      //       if (this.setNoneTimer != null) {
      //         return;
      //       }
      //       this.setNoneTimer = setTimeout(() => {
      //         exit_icon.style.display = "none";
      //         console.log("none");
      //         this.setNoneTimer = null;
      //       }, 1000);
      //     };

      //     // this.setNoneTimer = null;
      //   };
      // }
    },
    // 退出全屏按钮
    MapZoom_Exit() {
      this.isFull = false;
      console.log("点击了MapZoom_Exit", this.isFull);
      let element = document.getElementById("screen"); //指定全屏区域元素
      screenfull.exit(element); //退出全屏
    },
    getPopupContainer() {
      return document.getElementById("exit_icon");
    },
  },
};
</script>

<style lang="less" scoped>
.explain_map {
  height: 25px;
  line-height: 15px;
  text-align: left;
  p {
    margin: 0;
    padding: 0;
  }
}
.explain_map_flex {
  display: flex;
  justify-content: space-between;
}
icon {
  width: 30px;
}
.info {
  width: 300px;
}
.bm-view {
  width: 100%;
}
.status-con {
  width: 150px !important;
  background: #fff;
  border: rgba(0, 0, 0, 0.342) solid 1px;
  // border-radius: 16px;
  // margin: 20px ;
  display: block;
}
.status-item {
  margin: 10px 0;
  // padding: 0 15px;
}
.status-item + .status-item {
  border-left: 1px solid #ccc;
}
// .status-item:hover {
//   //   cursor: pointer;
//   //   color: #1890ff;
// }
.status1 {
  color: #52c41a;
}
.status2 {
  // color: #faad14;
  color: #397ef9;
}
.status3 {
  color: #f5222d;
}
.status-icon {
  display: block;
  padding-bottom: 5px;
  font-size: 18px;
}

.TClist {
  height: 40px;
  line-height: 40px;
  span {
    background-color: black;
    color: #fff;
    margin-right: 2px;
    padding: 9px;
    a {
      color: white;
    }
  }
  .zhibo {
    background: url(../../assets/images/site_img/zhibo.png);
  }
  .huifang {
    background: url(../../assets/images/site_img/huifang.png);
  }
  .kaoqin {
    background: url(../../assets/images/site_img/kaoqin.png);
  }
  .kuaizhao {
    background: url(../../assets/images/site_img/kuaizhao.png);
  }
  .xiangqing {
    background: url(../../assets/images/site_img/zhibo.png);
  }
}
.bm-marker-icon /deep/ .BMap_Marker {
  background-size: 100%;
}
/deep/ .BMap_cpyCtrl,
/deep/ .anchorBL {
  display: none;
}

// /deep/.ant-tooltip-inner {
//   // width: 50px;
// }
.map_quanpingBTN {
  height: 25px;
}
#exit_icon {
  width: 100%;
  // width: 100px;
  // background-color: pink;
  // text-align: right;
  // margin-left: 90%;
  display: flex;
  justify-content: right;
  display: none;
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
  float: right;
  .fullscreen_exit {
    font-size: 20px;
  }
}
.BMap_noprint {
  display: flex;
  width: 120px;
  justify-content: space-between;
}
.exit_control {
  position: static !important;
  float: right;
}
#components-a-tooltip-demo-placement .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}
.fullscreen_img,
.fullscreen_exit_img {
  width: 100%;
  height: 100%;
}
.exit_icon {
  height: 20px;
}
</style>
