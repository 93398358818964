<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :loading="loading"
      @change="handleTableChange"
      rowKey="id"
      bordered
      size="small"
    >
      <template slot="footer">
        <div class="foot-cont">
          <span class="foot-item">
            今日到岗人数:
            {{ resultData.worker_num }}</span
          >
          <span class="foot-item">
            工地管家本月打卡天数
            <a-tooltip>
              <template slot="title">本工地管家本工地本月打卡天数</template>
              <a-icon type="question-circle" /> </a-tooltip
            >： {{ resultData.worker_gj_num }}</span
          >
          <span class="foot-item">
            项目经理本月打卡天数
            <a-tooltip>
              <template slot="title">本工地项目经理本工地本月打卡天数</template>
              <a-icon type="question-circle" /> </a-tooltip
            >： {{ resultData.worker_jl_num }}</span
          >
        </div>
      </template>

      <span slot="w_name" slot-scope="text">
        {{ text ? text : "-" }}
      </span>
      <span slot="w_post" slot-scope="text">
        {{ text ? text : "-" }}
      </span>
      <span slot="w_date" slot-scope="text">
        {{ text ? $Format(text).split(" ")[0] : "" }}
      </span>
      <span slot="time_onwork" slot-scope="text">
        {{ text ? $Format(text).split(" ")[1] : "" }}
      </span>
      <span slot="time_offwork" slot-scope="text">
        {{ text ? $Format(text).split(" ")[1] : "-" }}
      </span>
      <span slot="time_work_sum" slot-scope="text">
        {{ text ? text : "-" }}
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <span @click="goToOthers(record)">
          {{
            record.work_type == 1
              ? "打卡"
              : record.work_type == 2
              ? "穿工服未打卡"
              : ""
          }}
        </span>
      </span>

      <span slot="有人施工标签" class="TitleFlex">
        <div>有人施工标签</div>
        <div>
          <a-tooltip>
            <template slot="title"
              >绑定期间有人打卡或现场出现穿工服者视为有人施工</template
            >
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </div>
      </span>
    </a-table>
    <a-pagination
      size="middle"
      v-model="current"
      :pageSize="pageSize"
      @change="pageNumberChange"
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :total="total"
      showSizeChanger
      style="margin: 20px 0; text-align: right"
    />
    <a-modal title="施工日历" okText="关闭" :visible="visible" @ok="closeCal">
      <div
        :style="{
          width: '300px',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
        }"
      >
        <a-calendar :fullscreen="false" @panelChange="onPanelChange" />
      </div>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: "日期",
    dataIndex: "w_date",
    scopedSlots: { customRender: "w_date" },
    align: "center",
    width: "100px",
  },
  {
    title: "员工姓名",
    dataIndex: "w_name",
    scopedSlots: { customRender: "w_name" },
    align: "center",
  },
  {
    title: "岗位/工种",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_post" },
    align: "center",
  },
  {
    title: "人员所属分公司",
    dataIndex: "ws_name",
    align: "center",
    width: "150px",
    ellipsis: true,
  },
  {
    title: "上班时间",
    dataIndex: "time_onwork",
    scopedSlots: { customRender: "time_onwork" },
    align: "center",
  },
  {
    title: "下班时间",
    dataIndex: "time_offwork",
    scopedSlots: { customRender: "time_offwork" },
    align: "center",
  },
  {
    title: "工作时长",
    dataIndex: "time_work_sum",
    scopedSlots: { customRender: "time_work_sum" },

    align: "center",
  },
  {
    slots: { title: "有人施工标签" },
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "TableMerge",
  components: {},
  props: {
    attendData: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataSource: [],
      columns,
      visible: false,
      // pagintion: { page: 1, pagesize: 10, totle: "" },
      pagintion: { totle: "20" },
      paCurrent_Size: {},
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      resultData: {},
    };
  },
  created() {},
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.initData();
      }
    },
  },
  mounted() {
    this.pagintion.totle = 20;
    // console.log(this.attendData, this.pagintion.totle);
  },
  methods: {
    initData() {
      this.resultData = this.attendData;
      // console.log(this.resultData);
      let result = this.attendData.data;
      // this.pagintion.totle = this.attendData.sum;
      this.total = this.attendData.sum;

      // 表格数据处理
      let list = [];
      result.forEach((item, index) => {
        let obj = {};
        obj.id = index;
        obj.showCalendar = false;
        obj.w_date = item.a_time1;
        obj.w_name = item.w_name;
        obj.time_onwork = item.a_time1;
        obj.time_offwork = item.a_time2;
        obj.ws_name = item.ws_name;
        let time = "";
        if (
          item.a_time1 != "" &&
          item.a_time2 != "" &&
          item.a_time1 &&
          item.a_time2
        ) {
          time = item.a_time2 - item.a_time1;
        }
        obj.time_work_sum = time == "" ? "" : this.calcTime(time);
        obj.w_post = item.w_post;
        obj.work_type = item.work_type;
        list.push(obj);
      });
      // this.pagintion.totle = list.length;

      this.dataSource = list;
    },
    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      console.log("pager", pager);
      this.paCurrent_Size = pagination;
      this.$emit("paCurrent_Size", this.paCurrent_Size);
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      console.log("分页页数的改变", current, size);
      this.current = current;
      this.pageSize = size;

      this.getShowSource();
    },
    //  分页显示条数的变化
    sizeChange(current, size) {
      console.log("分页显示条数的变化", current, size);
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      this.paCurrent_Size = {
        current: this.current,
        pageSize: this.pageSize,
      };
      this.$emit("paCurrent_Size", this.paCurrent_Size);
      // this.$set(this.dataSource, [], dataSource);
    },
    closeCal() {
      this.visible = false;
    },
    onPanelChange(value, mode) {
      console.log(value, mode);
    },
    calcTime(time) {
      let hour = Math.floor(time / 3600);
      let minute = Math.ceil((time - hour * 3600) / 60);
      return hour + "h" + (minute ? minute + "m" : "");
    },

    // 打开新窗口  跳转到人员考勤  携带员工姓名，日期岗位
    goToOthers(record) {
      if (record.work_type == 1) {
        //1  打卡
        // console.log("goToOthers", record);
        // localStorage.setItem("path", "WorkerAttend");
        // const { href } = this.$router.resolve({
        //   path: "/WorkerAttend",
        //   query: {
        //     start_time: record.time_onwork,
        //     end_time: record.time_onwork,
        //     w_name: record.w_name,
        //     ws_name: record.ws_name,
        //     w_post:
        //       record.w_post == "工程管家"
        //         ? 1
        //         : record.w_post == "项目经理"
        //         ? 2
        //         : "", //1 管家   2 经理
        //   },
        // });
        // window.open(href, "_blank");
        this.$store.commit("SET_CURRENT", "WorkerAttend");
        this.$router.push({
          path: "/WorkerAttend",
          query: {
            start_time: record.time_onwork,
            end_time: record.time_onwork,
            w_name: record.w_name,
            ws_name: record.ws_name,
            w_post:
              record.w_post == "工程管家"
                ? 1
                : record.w_post == "项目经理"
                ? 2
                : "", //1 管家   2 经理
          },
        });
      } else if (record.work_type == 2) {
        //2 穿工服未打卡
        // console.log("穿工服未打卡", record);
        // /* 点击穿工服跳转  报警事件管理中，新窗口打开，附带参数值（日期、报警事件“穿工服”、人员识别“非员工”、其他默认空或者全部）；【穿工服未签到】 */
        // localStorage.setItem("path", "AlarmEvent");
        // const { href } = this.$router.resolve({
        //   path: "/AlarmEvent",
        //   query: {
        //     e_time_start: record.time_onwork,
        //     e_time_end: record.time_onwork,
        //     ws_name: record.ws_name,
        //     e_events: "haveclothes", //穿工服
        //     si_eventworker: "2", //为非员工
        //   },
        // });
        // window.open(href, "_blank");
        this.$store.commit("SET_CURRENT", "AlarmEvent");
        this.$router.push({
          path: "/AlarmEvent",
          query: {
            e_time_start: record.time_onwork,
            e_time_end: record.time_onwork,
            ws_name: record.ws_name,
            e_events: "haveclothes", //穿工服
            si_eventworker: "2", //为非员工
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.foot-cont {
  width: 100%;
}
.foot-item {
  display: inline-block;
  width: 33%;
}
.border {
  border-right: 1px solid #ccc;
}
.calendar {
  width: "300px";
  border: "1px solid #d9d9d9";
  border-radius: "4px";
  position: absolute;
  display: none;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
.action {
  color: blue;
  cursor: pointer;
}
</style>
